import React, { useState } from 'react';
import {
	FaAngleRight,
	FaAngleLeft,
	FaShoppingCart,
	FaSignOutAlt,
	FaUsers,
	FaAngleDown,
	FaHouseUser,
	FaCar,
	FaUserSecret,
	FaWallet,
	FaIdCardAlt,
	FaCalendarTimes,
	FaUser,
	FaPrint,
	FaHandshake,
} from 'react-icons/fa';

import { AiTwotonePhone } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import { Image } from 'antd';

const ICON_SIZE = 20;

function Navbar({ visible, show, onLogout }) {
	const [isSubMenuOpen, setSubMenuOpen] = useState(false);
	const [isSubMenuOpenIntensive, setSubMenuOpenIntensive] = useState(false);

	const toggleSubMenu = () => { setSubMenuOpen(!isSubMenuOpen) };
	const toggleSubMenuIntensive = () => { setSubMenuOpenIntensive(!isSubMenuOpenIntensive) };

	// Retrieve user properties
	const username = localStorage.getItem('username');
	const idDepartment = localStorage.getItem('idDepartment');
	const idSep = localStorage.getItem('idSep');
	const roleId = localStorage.getItem('roleId');

	const handleLogout = () => {
		localStorage.removeItem('token');
		// window.location.reload(false)
		// window.location.href = '/';
		onLogout();
	};

	return (
		<>
			<nav className={!visible ? 'navbar' : ''}>
				<div className='scollheight'>
					<button
						type="button"
						className="nav-btn"
						onClick={() => show(!visible)}
					>
						{!visible
							? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
					</button>
					<div style={{ marginTop: '8px' }}>
						<div className="logo">
							<NavLink to="/">
								<Image
									src={require("../../assets/Images/logo-yellow.png")}
									alt="logo"
									preview={false}
									style={{
										color: 'white',
										border: 'none',
										borderRadius: '8px'
									}}
								/>
							</NavLink>
						</div>
						<div className="links nav-top">
							<NavLink className="nav-link">
								<FaUser size={ICON_SIZE} />
								<div className='center'>
									{/* <span><b>รหัส : </b></span> */}
									<span><b>{username}</b></span>
								</div>
							</NavLink>
							<NavLink to="/" className="nav-link">
								<FaHouseUser size={ICON_SIZE} />
								<span><b>หน้าหลัก</b></span>
							</NavLink>
							{(idDepartment === '1' || roleId === '39') && (
								<NavLink to="/operator" className="nav-link">
									<AiTwotonePhone size={ICON_SIZE} style={{ transform: 'rotate(90deg)' }} />
									<span><b>โอเปอร์เตอร์</b></span>
								</NavLink>
							)}
							{(idDepartment === '1' || roleId === '40') && (
								<NavLink to="/collector" className="nav-link">
									<FaShoppingCart size={ICON_SIZE} />
									<span><b>Collector</b></span>
								</NavLink>
							)}
							{(idDepartment === '1' || roleId === '30') && (
								<div>
									<div onClick={toggleSubMenu}>
										<h4>
											<div className="nav-link" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<FaHandshake size={ICON_SIZE} />
													<span style={{ marginLeft: 8 }}>เร่งรัด-หนี้สิน</span>
												</div>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{isSubMenuOpen ? (
														<FaAngleDown size={ICON_SIZE} style={{ transform: 'rotate(180deg)' }} />
													) : (
														<FaAngleDown size={ICON_SIZE} />
													)}
												</div>
											</div>
										</h4>
										<div style={{ position: 'relative' }}>
											{isSubMenuOpen && (
												<div style={{ paddingLeft: '20px' }}>
													<NavLink to="/car" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaCar size={ICON_SIZE} />
														<span>รถ</span>
													</NavLink>
													<NavLink to="/follow_case" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaUsers size={ICON_SIZE} />
														<span>ติดตามเคส</span>
													</NavLink>
													<NavLink to="/suspensioncase" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaCalendarTimes size={ICON_SIZE} />
														<span>เคสที่โดนระงับ</span>
													</NavLink>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
							{(idDepartment === '1' || roleId === '37') && (
								<div>
									<div onClick={toggleSubMenuIntensive}>
										<h4>
											<div className="nav-link" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<FaHandshake size={ICON_SIZE} />
													<span style={{ marginLeft: 8 }}>เร่งรัด-ภาคสนาม</span>
												</div>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{isSubMenuOpenIntensive ? (
														<FaAngleDown size={ICON_SIZE} style={{ transform: 'rotate(180deg)' }} />
													) : (
														<FaAngleDown size={ICON_SIZE} />
													)}
												</div>
											</div>
										</h4>
										<div style={{ position: 'relative' }}>
											{isSubMenuOpenIntensive && (
												<div style={{ paddingLeft: '20px' }}>
													<NavLink to="/cars_expedite" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaIdCardAlt size={ICON_SIZE} />
														<span>พอร์ต</span>
													</NavLink>
													<NavLink to="/contno_detaile" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaWallet size={ICON_SIZE} />
														<span>รับเข้าค่างวด</span>
													</NavLink>
													<NavLink to="/pay" className="nav-link" onClick={(e) => e.stopPropagation()}>
														<FaUserSecret size={ICON_SIZE} />
														<span>Treller</span>
													</NavLink>

												</div>
											)}
										</div>
									</div>
								</div>
							)}
							{(idDepartment === '1' || roleId === '41') && (
								<NavLink to="/call_team_status" className="nav-link">
									<FaPrint size={ICON_SIZE} />
									<span><b>พิมพ์รายงาน</b></span>
								</NavLink>
							)}

						</div>
						{/* เพิ่ม container สำหรับ "ออกจากระบบ" */}
						<div className="logout-container">
							<NavLink to="/" className="nav-link" onClick={handleLogout}>
								<FaSignOutAlt size={ICON_SIZE} />
								<span><b>ออกจากระบบ</b></span>
							</NavLink>
						</div>

					</div>
				</div>
			</nav>
		</>
	);
}

export default Navbar;
