import React from 'react'
import { Col, Row, Table } from 'antd';
import { Tracking_Record_Schedule_columns, Tracking_Record_Schedule_data } from '../../Data_Tables';

const Tracking_Record_Schedule = () => {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: 10 }}>
                <Table
                    columns={Tracking_Record_Schedule_columns}
                    dataSource={Tracking_Record_Schedule_data}
                    onChange={onChange}
                />
            </Col>
        </Row>
    )
}

export default Tracking_Record_Schedule