import { Col, Row, Spin, Card, Table, DatePicker, message, Form, Select, Button } from 'antd'
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import dayjs from "dayjs";
import { getcts_detail } from '../mid/all-api';
import { FaDatabase } from 'react-icons/fa';

function MainPay() {
    // const getcts_detail = `http://localhost:8080/api-db2/getcts_detail`
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [dataArray, setDataArray] = useState()
    // const dayCurrent = new Date();
    // dayCurrent.setDate(dayCurrent.getDate() - 1); // ลดวันที่ลง 1 วัน
    // const year = dayCurrent.getFullYear();
    // const month = String(dayCurrent.getMonth() + 1).padStart(2, '0'); // เพิ่ม 1 เพราะเดือนเริ่มต้นจาก 0
    // const day = String(dayCurrent.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    const dayCurrent = dayjs(); // วันที่ปัจจุบัน
    const dayBeforeCurrent = dayjs().subtract(1, 'day'); // วันที่ก่อนหน้าวันปัจจุบัน 1 วัน
    const [dateSearch, setDateSearch] = useState("")
    const user = localStorage.getItem("username")
    const nicknameSalcod = localStorage.getItem("nicknameSalcod")
    const [selectedValueType, setSelectedValueType] = useState(1);
    const [checkLoad, setCheckLoad] = useState(0);


    const loadDatagetcts_detail = async () => {

        var data_type
        if (selectedValueType === 1) {
            data_type = 'lsfhp'
        } else if (selectedValueType === 2) {
            data_type = 'vsfhp'
        } else if (selectedValueType === 3) {
            data_type = 'rpsl'
        } else if (selectedValueType === 4) {
            data_type = 'sfhp'
        }

        const papams = {
            date: dateSearch,
            BILLCOLL: user,
            nicknameSalcod: nicknameSalcod,
            DATA_TYPE: data_type

        }

        setLoading(true)
        await axios.post(getcts_detail, papams)
            .then((res) => {
                // console.log("res.data", res.data)
                if (res.data) {
                    //เพิ่ม key ให้กับแต่ละรายการ
                    const dataWithKeys = res.data.map((item, index) => ({
                        ...item,
                        key: index, // ใช้ index เป็น key
                    }));


                    dataWithKeys.sort((a, b) => {
                        const contnoA = a.CONTNO || "";
                        const contnoB = b.CONTNO || "";

                        if (contnoA < contnoB) return -1;
                        if (contnoA > contnoB) return 1;
                        return 0; // กรณีที่เท่ากัน

                        // หาก CONTNO เท่ากัน ให้เรียงตามวันที่
                        // const dateA = new Date(a.PAYDT || a.TEMPDATE);
                        // const dateB = new Date(b.PAYDT || b.TEMPDATE);
                        // return dateB - dateA; // เรียงวันที่จากล่าสุด
                    });

                    // dataWithKeys.sort((a, b) => {
                    //     const dateA = new Date(a.PAYDT || a.TEMPDATE);
                    //     const dateB = new Date(b.PAYDT || b.TEMPDATE);
                    //     // เปรียบเทียบเพื่อเรียงจากล่าสุด
                    //     return dateB - dateA;
                    // });

                    setDataArray(dataWithKeys); // เซ็ตข้อมูลพร้อม key
                    setCheckLoad(1)
                } else {
                    setDataArray([]); // เซ็ตข้อมูลพร้อม key
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onSelect = (value) => {
        console.log("value", value);
        setSelectedValueType(value);
    };
    const countoptionsType = () => {
        const options = [
            // { value: 0, label: `ทั้งหมด (${countstype[0].value})` },
            { value: 1, label: `บัญชี 1` },
            { value: 2, label: `บัญชี 2` },
            { value: 3, label: `บัญชี 3` },
            { value: 4, label: `บัญชี 8` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "8%",
            render: (text, object, index) => (
                <td data-label="ลำดับ : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {index + 1}
                </td>
            ),
        },
        {
            title: "เลขสัญญา",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: 'center',
            render: (text, record, index) => (
                <td data-label="เลขสัญญา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.CONTNO || "ไม่มีเลขสัญญา"}
                </td>
            ),
        },
        {
            title: "REF2",
            dataIndex: "REF2",
            key: "REF2",
            align: 'center',
            render: (text, record) => (
                <td data-label="REF2 : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.REF2 || "-"}
                </td>
            ),
        },
        {
            title: "เงินที่โอนเข้ามา",
            dataIndex: "money",
            key: "money",
            align: 'center',
            render: (text, record, index) => (
                <td data-label="เงินที่โอนเข้ามา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.money ? currencyFormatOne(record.money) + ' ' + 'บาท' : '-'}
                </td>
            ),

        },
        {
            title: "วันที่จ่ายเข้ามา",
            dataIndex: "PAYMENTDATE",
            key: "PAYMENTDATE",
            align: 'center',
            render: (text, record, index) => (
                <>
                    <td data-label="วันที่จ่ายเข้ามา : "
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            height: "100%"
                        }}
                    >
                        {record.PAYMENTDATE ? dayjs(record.PAYMENTDATE).format("DD-MM-YYYY") : '-'}
                    </td>
                </>
            ),
        },
    ];

    return (
        <Card
            style={{
                height: '100vh',
            }}
        >
            <Spin spinning={loading} size="large" tip="Loading..." >

                <Row gutter={32} justify={'center'}>
                    <Col>
                        <div className="text-center">
                            <h2>Treller</h2>
                        </div>
                    </Col>
                </Row>
                <Row justify={'center'} className='mains'>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true, }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <aside>
                            <div style={{ display: '-ms-flexbox', lineHeight: '40px', margin: 2, textAlign: 'right' }}>
                                <Form.Item name="date">
                                    <div style={{ textAlign: 'center' }}>
                                        <span>วันที่ : </span>
                                        <DatePicker
                                            name="date"
                                            format={'YYYY-MM-DD'}
                                            defaultValue={dayBeforeCurrent} // ค่าเริ่มต้นเป็นวันที่ก่อนปัจจุบัน
                                            style={{ height: '40px', width: 250 }}
                                            disabledDate={(currentDate) => {
                                                // ปิดการเลือกวันที่ที่เป็นปัจจุบันหรือในอนาคต
                                                return currentDate && currentDate.isAfter(dayBeforeCurrent, 'day');
                                            }}
                                            onChange={(e) => {
                                                if (e) {
                                                    const selectedDate = e.toDate();
                                                    if (selectedDate >= dayCurrent.toDate()) {
                                                        message.info('ข้อมูลยังไม่ถึงวันที่ที่เลือก');
                                                    } else {
                                                        setDateSearch(e.format("YYYY-MM-DD"));
                                                    }
                                                }
                                            }}
                                        />
                                        <br />
                                        <span style={{ textAlign: 'center', color: 'red' }}>
                                            ** วันปัจจุบัน จะไม่สามารถดูข้อมูลได้ **
                                        </span>
                                    </div>
                                </Form.Item>
                            </div>
                            <div style={{ display: '-ms-flexbox', lineHeight: '40px', margin: 2, textAlign: 'right' }}>
                                <span>เลือกบัญชี : </span>
                                <Select
                                    style={{ width: 250, height: '40px' }}
                                    value={selectedValueType}
                                    onChange={onSelect}
                                >
                                    {countoptionsType()}
                                </Select>
                            </div>
                            <div style={{ margin: 2, textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: "10px", color: 'white', height: '40px', background: '#302e94f7' }}
                                    onClick={loadDatagetcts_detail}
                                >
                                    <FaDatabase />
                                    <b>ดึงข้อมูล</b>
                                </Button>
                            </div>
                        </aside>
                    </Form>
                </Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="responsive-table-wrapper">
                        <Table
                            className="responsive-table"
                            columns={columns}
                            dataSource={dataArray}
                            scroll={{ x: 'max-content', y: 600 }}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                </Col>
            </Spin>

        </Card>
    )
}

export default MainPay