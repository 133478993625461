import { useState, useEffect } from "react";
import Contents from "./Contents";
import { useNavigate } from 'react-router-dom';
import Navbar from "./Navbar";
import "./index.css"

function Main() {


  const navigate = useNavigate();
  const [navVisible, showNavbar] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsAuthenticated(true);
  }, [token]);

  const handleLogout = ({ value }) => {
    console.log("vallll",value)
    localStorage.clear();       // ลบข้อมูลที่จัดเก็บไว้ใน localStorage
    setIsAuthenticated(false); // อัปเดตสถานะการเข้าสู่ระบบ
    showNavbar(false);         // รีเซ็ต Navbar ให้เป็นปิด
  };

  return (
    <>
      <div className={`App ${navVisible ? 'page-with-navbar' : 'page'}`}>
        {isAuthenticated &&
          (
            <Navbar
              visible={navVisible}
              show={showNavbar}
              className={navVisible ? 'navbar navbar-visible' : 'navbar'}
              onLogout={handleLogout}
            />
          )}
        <div className={`main ${isAuthenticated && navVisible ? 'page-with-navbar' : 'page'}`} style={{ background: "#ffffff" }}>
          <Contents />
        </div>
      </div>

    </>
  )
}

export default Main
