import { Col, Image, Row } from 'antd'
import React from 'react'

function Main() {
    return (
        <Row justify={'center'} >
            <Col span={24} style={{ textAlign: 'center', alignItems: 'center' }} align="center">
                <Image
                    src={require("../assets/Images/drawable-header.png")}
                    alt="logo"
                    preview={false}
                    style={{
                        width: '70%',
                        color: 'white',
                        border: 'none',
                        borderRadius: '8px'
                    }}
                />
            </Col>
        </Row>
    )
}

export default Main