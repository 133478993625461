// https://api-debts-539174983798.asia-southeast1.run.app

export const getchqtran = `https://api-debts-539174983798.asia-southeast1.run.app/getchqtran`   //เส้นเดิม
// export const results = `http://localhost:8080/api-dets/results`   //เส้นเดิม
export const results = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/results`   //เส้นใหม่
export const exdept = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/exdept`   //เส้นใหม่
export const detailPaymen = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/detail-payment`;  //เส้นใหม่ ตารางการผ่อน
export const getexpreal = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/get-expreal`;  //เส้นใหม่ ตารางการผ่อน
export const updateexpreal = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/update-expreal`;  //เส้นใหม่ ตารางการผ่อน


export const getcts_detail = `https://api-debts-539174983798.asia-southeast1.run.app/api-dets/teller`

export const login = `https://login-api-i67f6gaaqa-as.a.run.app/login-mongo`
export const genQrCodr = `https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/conref2/`

export const showcausename = `https://garfe-i67f6gaaqa-as.a.run.app/api/showcausename`;
export const loadDetailAunSendPG = `https://pg-api-i67f6gaaqa-as.a.run.app/api/load-detail-aunsendpg`;

export const loadMainBoss = `https://api-manager-i67f6gaaqa-as.a.run.app/boss/load-main-boss`; 
export const mainexpeditethedebt = `https://api-manager-i67f6gaaqa-as.a.run.app/midle/main-expeditethedebt`;

export const QRCODE_URL = "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans"; //gen QRCode

// export const getcts_detail = `https://api-debts-539174983798.asia-southeast1.run.app/api-db2/getcts_detail`


// export const loadMainBoss = `https://api-manager-i67f6gaaqa-as.a.run.app/boss/load-main-boss`; // 15/07/2024  ยังไม่ได้มีการเรียกใช้,
// export const mainexpeditethedebt = `https://api-manager-i67f6gaaqa-as.a.run.app/midle/main-expeditethedebt`;

// const insertcase = `https://api-manager-i67f6gaaqa-as.a.run.app/midle/main-expeditethedebt`;
// const loaddetailmysqlDB2 = `https://api-manager-i67f6gaaqa-as.a.run.app/api-manager/load-detail-mysqlDB2`;