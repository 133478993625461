import React, { useState } from 'react';
import { Table, Card, Divider, Spin, Col, Row } from 'antd';

const MainCollector = () => {
  const [loading, setLoading] = useState(false)


  return (
    <>
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>Collector</u></b></Divider>
          </Spin>
        </Col>
      </Row>

    </>
  )
};
export default MainCollector;