import React, { useEffect, useState } from 'react'
import { Row, Form, Input, Col, Divider, Card, Table, Button } from 'antd'
import { guarantor_data, history_data } from '../Data_Tables';
import Item from 'antd/es/list/Item';
const { TextArea } = Input;

const columns1 = [
    {
        title: "ลำดับ",
        dataIndex: "index",
        key: 'index',
        align: 'center',
        width: "20%",
        render: (text, object, index) => index + 1
    },
    {
        title: "phone",
        dataIndex: "phone",
        key: 'phone',
        align: 'center',
        width: "60%",
        render: (text, record) => (
            <>{record.phone}</>
        ),
    },

]


function Contract_Details() {
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [guarantor, setGuarantor] = useState()
    const [address, setAddress] = useState()


    useEffect(() => {
        const addres = history_data?.map((item, index) => ({
            ...item,
            key: `ลำดับที่อยู่-${item.id || index + 1}`, // เพิ่ม prefix "history-" ให้ `key`
            index
        }));
        const gua = guarantor_data?.map((item, index) => ({
            ...item,
            key: `ผู้ค้ำประกัน-${item.id || index + 1}`, // เพิ่ม prefix "guarantor-" ให้ `key`
            index
        }));
        setAddress(addres);
        setGuarantor(gua);
    }, [expandedRowKey]);



    const onExpand = (expanded, record) => {
        setExpandedRowKey(expanded ? record.key : null);
    };


    // const renderhistory = ({ item, index }) => {
    //     console.log("item", item)
    //     const datas = [{ ...item }]

    //     const datas = [
    //         {
    //             key: 1,
    //             phone: item?.phone,
    //             CONTNO: item?.CONTNO,
    //             customer_id: item?.customer_id,
    //             name: item?.name,
    //             surname: item?.surname,
    //             nickname: item?.nickname,
    //             identification_id: item?.identification_id,
    //             registration_number: item?.registration_number,
    //             description: (
    //                 <Row justify={'center'}>
    //                     {/* <Col span={24} style={{ textAlign: 'center', fontSize: '18px' }}>
    //                         <p><b>รายละเอียด</b></p>
    //                     </Col> */}
    //                     <Col span={24}>
    //                         <Form.Item label='หมายเหตุ : '>
    //                             <Input
    //                                 style={{ display: "inline-block" }}
    //                                 placeholder="Enter additional information"
    //                             // value={inputs[1]}
    //                             // onChange={(e) => handleInputChange1(1, e)}
    //                             />
    //                         </Form.Item>
    //                     </Col>
    //                     <Col style={{ textAlign: 'right' }}>
    //                         <Button
    //                             type="primary"
    //                         // onClick={() => handleSave(data[0])} // ใช้ข้อมูลจาก record ในฟังก์ชัน handleSave
    //                         // disabled={inputs[1].trim() === ''} // ปิดการใช้งานปุ่มถ้าไม่มีการกรอกข้อมูล
    //                         >
    //                             บันทึก
    //                         </Button>
    //                     </Col>
    //                 </Row>
    //             ),
    //         },
    //     ];
    //     console.log("datas", datas)

    //     console.log("dataaaaaaaaaaa", datas)

    //     return (
    //         <>
    //             <Table
    //                 columns={columns1}
    //                 expandable={{
    //                     expandedRowRender: (record) => (
    //                         <p
    //                             style={{
    //                                 margin: 0,
    //                             }}
    //                         >
    //                             {record.description}
    //                         </p>
    //                     ),
    //                     rowExpandable: (record) => record.name !== 'Not Expandable',
    //                 }}
    //                 dataSource={guarantor}
    //             />
    //             {/* <Card>
    //                 <Divider orientation="center" style={{ margin: 2 }}>
    //                     <b><u>ลำดับที่อยู่ {index + 1}</u></b>
    //                 </Divider>
    //                 <div label='กลุ่มลูกค้า' name='contract_number'>
    //                     <span><b> กลุ่มลูกค้า : </b></span>
    //                     <span><b>{item?.customer_id}</b></span>

    //                 </div>
    //                 <div label='รหัสลูกค้า' name='contract_number'>
    //                     <span><b> รหัสลูกค้า : </b></span>
    //                     <span><b>{item?.identification_id}</b></span>
    //                 </div>
    //                 <div label='เลขที่บัตร' name='contract_number'>
    //                     <span><b> เลขที่บัตร : </b></span>
    //                     <span><b>{item?.identification_id}</b></span>
    //                 </div>
    //                 <div label='วันเกิด' name='contract_number'>
    //                     <span><b> วันเกิด : </b></span>
    //                     <span><b>{item?.date_of_birth}</b></span>
    //                 </div>
    //                 <div label='เบอร์โทร' name='contract_number'>
    //                     <span><b> เบอร์โทร : </b></span>
    //                     <span><b>{item?.phone}</b></span>
    //                 </div>
    //                 <div label='ที่ทำงาน' name='contract_number'>
    //                     <span><b> ที่ทำงาน : </b></span>
    //                     <span><b>{item?.workplace}</b></span>
    //                 </div>
    //                 <div>
    //                     <span><b> ที่อยู่ : </b></span>
    //                     <span style={{ width: '90%' }}>
    //                         <TextArea
    //                             rows={5}
    //                             placeholder="maxLength is 150"
    //                             maxLength={150}
    //                             value={item?.address}
    //                         />
    //                     </span>
    //                 </div>
    //             </Card> */}
    //         </>
    //     )
    // }

    // const renderGuarantors = ({ item, index }) => {
    //     return (
    //         <>
    //             <Card>
    //                 <Divider orientation="center" style={{ margin: 2 }}>
    //                     <b><u>ผู้ค้ำประกัน {index + 1}</u></b>
    //                 </Divider>
    //                 <div label='ชื่อคำค้ำ' name='contract_number'>
    //                     <span> ชื่อคำค้ำ : </span>
    //                     <span><b>{item?.name + ' ' + item?.lastname}</b></span>

    //                 </div>
    //                 <div label='ความสัมพันธ์' name='contract_number'>
    //                     <span> ความสัมพันธ์ : </span>
    //                     <span><b>{item?.relationship}</b></span>
    //                 </div>
    //                 <div label='ชื่อเล่น' name='contract_number'>
    //                     <span> ชื่อเล่น : </span>
    //                     <span><b>{item?.nickname}</b></span>
    //                 </div>
    //                 <div label='เลขที่บัตร' name='contract_number'>
    //                     <span> เลขที่บัตร : </span>
    //                     <span><b>{item?.identification_id}</b></span>
    //                 </div>
    //                 <div label='เบอร์โทร' name='contract_number'>
    //                     <span> เบอร์โทร : </span>
    //                     <span><b>{item?.phone}</b></span>
    //                 </div>
    //                 <div label='ที่ทำงาน' name='contract_number'>
    //                     <span> ที่ทำงาน : </span>
    //                     <span><b>{item?.workplace}</b></span>
    //                 </div>
    //                 <div>
    //                     <span> ที่อยู่ : </span>
    //                     <span style={{ width: '90%' }}>
    //                         <TextArea
    //                             rows={5}
    //                             placeholder="maxLength is 150"
    //                             maxLength={150}
    //                             value={item?.address}
    //                         />
    //                     </span>
    //                 </div>
    //             </Card>
    //         </>
    //     )
    // }
    return (
        <Row style={{ width: '100%' }}>
            <Col span={12} style={{ padding: 10 }}>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label='ยอดกู้' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='ดอกเบี้ย' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='จำนวนผ่อน' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เบี้ยปรับ' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='ค่าธรรมเนียม' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เป็นเงิน' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='ค่างวด' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='งวดสุดท้าย' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='ยอดกู้สุทธิ' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='ผ่อนรวมดอกเบี้ย' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='งวดแรก' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>

                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label='ชำระเงินต้นแล้ว' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='ผู้ตรวจสอบ' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='วันที่เปลี่ยนสัญญา' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='วันหยุดรับรู้รายได้' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='ชำระดอกเบี้ยแล้ว' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='พนักงานเก็บเงิน' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เริ่มผ่อนชำระงวดที่' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='เงินต้นคงเหลือ' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='พนักงานขาย' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='ถึงวด' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Divider orientation="center" style={{ margin: 2 }}>
                        <b><u>ข้อมูลสินค้า</u></b>
                    </Divider>
                    <Col span={8}>
                        <Form.Item
                            label='ยี่ห้อสินค้า' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เลขตัวถัง' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='กลุ่มสินค้า' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='รุ่นสินค้า' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เลขเครื่อง' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='รุ่นปี' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label='สีสินค้า' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='เลขทะเบียน' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                        <Form.Item
                            label='จังหวัด' name='contract_number'
                        >
                            <Input
                            // style={{ width: '200px',  }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <Row style={{ marginBottom: '15px' }}>
                    <TextArea
                        rows={15}
                        placeholder="maxLength is 10,000"
                        maxLength={10000}
                    />
                </Row>
                <Col span={24} style={{ marginBottom: '15px' }}>
                    <Divider orientation="center" style={{ margin: 2 }}>
                        <b><u>ข้อมูลประวัติและที่อยู่</u></b>
                    </Divider>
                    <Row justify={'center'} >
                        <Table
                            style={{ width: '100%' }}
                            columns={columns1}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <p style={{ margin: 0 }}>
                                        {record.description}

                                        <Card>
                                            <Divider orientation="center" style={{ margin: 2 }}>
                                                <b><u>{record.key}</u></b>
                                            </Divider>
                                            <div label='กลุ่มลูกค้า' name='contract_number'>
                                                <span> กลุ่มลูกค้า : </span>
                                                <span><b>{record?.customer_id}</b></span>

                                            </div>
                                            <div label='รหัสลูกค้า' name='contract_number'>
                                                <span> รหัสลูกค้า : </span>
                                                <span><b>{record?.identification_id}</b></span>
                                            </div>
                                            <div label='เลขที่บัตร' name='contract_number'>
                                                <span> เลขที่บัตร : </span>
                                                <span><b>{record?.identification_id}</b></span>
                                            </div>
                                            <div label='วันเกิด' name='contract_number'>
                                                <span> วันเกิด : </span>
                                                <span><b>{record?.date_of_birth}</b></span>
                                            </div>
                                            <div label='เบอร์โทร' name='contract_number'>
                                                <span> เบอร์โทร : </span>
                                                <span><b>{record?.phone}</b></span>
                                            </div>
                                            <div label='ที่ทำงาน' name='contract_number'>
                                                <span> ที่ทำงาน : </span>
                                                <span><b>{record?.workplace}</b></span>
                                            </div>
                                            <div>
                                                <span> ที่อยู่ : </span>
                                                <span style={{ width: '90%' }}>
                                                    <TextArea
                                                        rows={5}
                                                        placeholder="maxLength is 150"
                                                        maxLength={150}
                                                        value={record?.address}
                                                    />
                                                </span>
                                            </div>
                                        </Card>
                                    </p>
                                ),
                                rowExpandable: (record) => record.name !== 'Not Expandable',
                                onExpand: onExpand,
                            }}
                            expandedRowKeys={expandedRowKey ? [expandedRowKey] : []} // กำหนดแถวที่ขยายได้
                            dataSource={address}
                        />
                        {/* <Table
                            columns={columns1}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <p
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {record.description}
                                    </p>
                                ),
                                rowExpandable: (record) => record.name !== 'Not Expandable',
                                onExpand: onExpand, // Add the onExpand handler
                            }}
                            expandedRowKeys={expandedRowKey ? [expandedRowKey] : []} // Control single row expansion
                            dataSource={guarantor}
                        /> */}
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: '15px' }}>
                    <Divider orientation="center" style={{ margin: 2 }}>
                        <b><u>ผู้ค้ำประกัน</u></b>
                    </Divider>
                    <Row justify={'center'}>
                        <Table
                            style={{ width: '100%' }}
                            columns={columns1}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <p style={{ margin: 0 }}>
                                        {record.description}

                                        <Card>
                                            <Divider orientation="center" style={{ margin: 2 }}>
                                                <b><u>{record.key}</u></b>
                                            </Divider>
                                            <div label='ชื่อคำค้ำ' name='contract_number'>
                                                <span> ชื่อคำค้ำ : </span>
                                                <span><b>{record?.name + ' ' + record?.lastname}</b></span>

                                            </div>
                                            <div label='ความสัมพันธ์' name='contract_number'>
                                                <span> ความสัมพันธ์ : </span>
                                                <span><b>{record?.relationship}</b></span>
                                            </div>
                                            <div label='ชื่อเล่น' name='contract_number'>
                                                <span> ชื่อเล่น : </span>
                                                <span><b>{record?.nickname}</b></span>
                                            </div>
                                            <div label='เลขที่บัตร' name='contract_number'>
                                                <span> เลขที่บัตร : </span>
                                                <span><b>{record?.identification_id}</b></span>
                                            </div>
                                            <div label='เบอร์โทร' name='contract_number'>
                                                <span> เบอร์โทร : </span>
                                                <span><b>{record?.phone}</b></span>
                                            </div>
                                            <div label='ที่ทำงาน' name='contract_number'>
                                                <span> ที่ทำงาน : </span>
                                                <span><b>{record?.workplace}</b></span>
                                            </div>
                                            <div>
                                                <span> ที่อยู่ : </span>
                                                <span style={{ width: '90%' }}>
                                                    <TextArea
                                                        rows={5}
                                                        placeholder="maxLength is 150"
                                                        maxLength={150}
                                                        value={record?.address}
                                                    />
                                                </span>
                                            </div>
                                        </Card>
                                    </p>
                                ),
                                rowExpandable: (record) => record.name !== 'Not Expandable',
                                onExpand: onExpand,
                            }}
                            expandedRowKeys={expandedRowKey ? [expandedRowKey] : []} // กำหนดแถวที่ขยายได้
                            dataSource={guarantor}
                        />
                        {/* {guarantor_data?.map((item, index) => {
                            return (
                                <React.Fragment key={`${item.id}-${index}`}>
                                    {renderGuarantors({ item, index })}
                                </React.Fragment>
                            );
                        })} */}
                    </Row>
                </Col>
            </Col>
        </Row>
    )
}

export default Contract_Details