import React, { useState, createRef, useEffect } from "react";
import { Button, Form, Input, Radio, Modal, Typography, Row, Col, Divider, Spin } from "antd";
import axios from "axios";
import { createFileName } from "use-react-screenshot";
import * as htmlToImage from "html-to-image";
import { genQrCodr, QRCODE_URL } from "../../mid/all-api";

export default function QRCodeWithContractNo({ open, close, dataQr }) {
  
  const [urlLink, setUrlLink] = useState();
  const [details, setDetails] = useState();
  const [ref2, setRef2] = useState("");
  const [contractNo, setContractNo] = useState("");
  const [payment, setPayment] = useState(0);
  const [isModalQrCode, setModalQrCode] = useState(null);
  const ref = createRef(null);
  const [loading, setLoading] = useState(false);

  const takeScreenShot = async (node) => {
    try {
      const options = {
        style: {
          // Inline CSS overrides styles in remote files
        },
      };
      const dataURI = await htmlToImage.toJpeg(node);
      return dataURI;
    } catch (error) {
      console.error("Failed to capture image:", error);
    }
  };

  const download = (image, { name = "qr code", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name, ` เลขที่สัญญา ${dataQr}`);
    a.click();
  };

  const getImage = () => {
    takeScreenShot(ref.current).then(download);
  };



  const onFinish = (values) => {
    console.log("FINISH", values);
    let amount;
    if (!values.amount) {
      amount = 0;
    } else {
      amount = values.amount;
      setPayment(amount);
    }
    const url =
      QRCODE_URL +
      "/qr/" +
      values.paymentWay +
      "/" +
      "00" +
      "/" +
      "006" +
      "/" +
      ref2 +
      "/" +
      amount * 100;

    console.log("Success:", values);
    setUrlLink(url);
    console.log(url);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setModalQrCode(true);
    if (isModalQrCode === true) {
      setLoading(true)
      setUrlLink("");
      const timeout = setTimeout(() => {
        searchContractNo(dataQr);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isModalQrCode]);

  const currencyFormat = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const searchContractNo = async (value) => {
    setLoading(true)
    console.log("QR", value);
    await axios.get(genQrCodr + value)
      .then((res) => {
        if (res.data) {
          const result = res.data.reduce((acc, item) => {
            if (item.CONTNO === dataQr) {
              acc = { ...item };
            }
            return acc;
          });
          setDetails(result);
          setRef2(result?.CONTNO_);
          setContractNo(dataQr);
          setLoading(false)
        }
      })
      .catch((err) => alert(err));
    setLoading(false)
  };

  const handleClose = () => {
    setModalQrCode(false);
    close(false);
  };

  return (
    <Modal
      title="สร้าง qr code สำหรับชำระเงิน"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          ปิด
        </Button>,
      ]}
    >
      {
        loading ?
          <>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "100vh", // ใช้เต็มความสูงของหน้าจอ
            }}>
              <Spin spinning={loading} size="large" tip="Loading..." />
            </div>
          </>
          :
          <>
            {details ? (
              <Row justify={'center'}>
                <Form
                  name="basic"
                  labelCol={{
                    span: 12,
                  }}
                  style={{
                    maxWidth: '100%',
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row justify={'center'}>
                    <Form.Item
                      style={{ fontWeight: "bold", lineHeight: '50px' }}
                      label={"ช่องทางชำระ"}
                      name="paymentWay"
                      rules={[
                        {
                          required: true,
                          message: "กรูณาใส่เลือกช่องทางชำระ",
                        },
                      ]}
                    >
                      <Radio.Group className="main1" style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <Radio value={"0405559001925"} style={{ width: '100%' }}>
                            ชำระผ่านธนาคาร
                          </Radio>
                        </div>
                        <div style={{ width: '100%' }}>
                          <Radio value={"0405552000435"} style={{ width: '100%' }}>
                            ชำระผ่านเค้าน์เตอร์เซอร์วิส
                          </Radio>
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                  <Row justify={'center'}>
                    <Col span={20}>
                      <aside>
                        <div>
                          <Typography>
                            <span>
                              <b>เลขที่สัญญา : </b>
                            </span>
                            <b>
                              {contractNo}
                            </b>
                          </Typography>
                        </div>
                        <div>
                          <Divider>รายละเอียด</Divider>
                          {details ? (
                            <>
                              <div>
                                <span>
                                  <b>ชื่อ : </b>
                                </span>
                                {details?.NAME || ""}
                              </div>
                              <div>
                                <span>
                                  <b>ทะเบียน/โฉนด : </b>
                                </span>
                                {details?.REGNO || ""}
                              </div>
                              <div>
                                <span>
                                  <b>ยี่ห้อ/ประเภท : </b>
                                </span> {details?.TYPE || ""}</div>
                              <div>
                                <span>
                                  <b>รุ่น/โมเดล : </b>
                                </span> {details?.MODEL || ""}</div>
                              <div>
                                <span>
                                  <b>สี : </b>
                                </span> {details?.COLOR || ""}</div>
                              <div>
                                <span>
                                  <b>เลขเครื่อง/เลขที่ : </b>
                                </span>
                                {details?.STRNO || ""}
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div>
                          <div>
                            <span>
                              <b>ref2 : </b>
                            </span>
                            {ref2}
                          </div>
                        </div>
                        <div>
                          <Form.Item label={<b>จำนวนเงิน</b>} name="amount" style={{ marginTop: '20px' }}>
                            <Input placeholder="หากไม่ใส่ ลูกค้าจะกำหนดยอดเองได้" style={{ width: '100%' }} />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            wrapperCol={{
                              offset: 8,
                              span: 16,
                            }}
                          >
                            <Button type="primary" htmlType="submit">
                              Generate QR Code
                            </Button>
                          </Form.Item>
                        </div>
                      </aside>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {urlLink ? (
                    <div style={{ width: 350 }}>
                      <div
                        ref={ref}
                        style={{
                          padding: 24,
                          backgroundColor: "white",
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>เลขที่สัญญา</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.CONTNO}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>ชื่อ</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.NAME}
                          </Typography>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>ทะเบียน/โฉนด</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.REGNO}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>ประเภท/ยี่ห้อ</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.TYPE}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>โมเดล/รุ่น</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.MODEL}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>แบบ</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.BAAB}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>สี</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.COLOR}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>เลขที่/เลขเครื่อง</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.STRNO}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>หมายเลขอ้างอิง ref2</Typography>
                          <Typography style={{ fontWeight: "bold" }}>
                            {details?.CONTNO_}
                          </Typography>
                        </div>

                        <img
                          alt=""
                          style={{ justifySelf: "center", display: "flex" }}
                          width={300}
                          src={urlLink}
                          crossOrigin="anonymous"
                        />

                        <Typography style={{ fontWeight: "bold" }}>
                          ยอดชำระ : {currencyFormat(payment)} บาท
                        </Typography>

                        {/* <Typography style={{ fontStyle: "italic" }}>
                        วันที่พิมพ์ {dateNow()}
                      </Typography> */}
                        <br />
                        <Typography style={{ color: "red", fontWeight: "bold" }}>
                          กรุณาตรวจสอบข้อมูลของท่านก่อนชำระงวด
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 24,
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            getImage();
                          }}
                        >
                          ดาวน์โหลด QR CODE
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Row>
            ) : null
            }
          </>
      }
    </Modal>
  );
}
