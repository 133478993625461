import React from 'react'
import { Col, Row, Table } from 'antd';
import { Installment_Deposit_Schedule_columns, Installment_Deposit_Schedule_data } from '../../Data_Tables';

const Installment_Deposit_Schedule = () => {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    return (
        <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: 10 }}>
                <Table
                    columns={Installment_Deposit_Schedule_columns}
                    dataSource={Installment_Deposit_Schedule_data}
                    onChange={onChange}
                />
            </Col>
        </Row>
    )
}

export default Installment_Deposit_Schedule