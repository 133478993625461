
import { Tag } from "antd";
import dayjs from "dayjs";
const currencyFormatOne = (amount) => {
    if (amount) {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
        return 0;
    }
};

export const DataColumsCar = [
    {
        title: <div style={{ textAlign: "center" }}>ลำดับ</div>,
        dataIndex: "key",
        key: "key",
        align: "center",
        onCell: () => ({
            "key": "ลำดับ",
        }),
        render: (text, object, key) => key + 1,
        sorter: {
            compare: (a, b) => a.key - b.key,
            multiple: 5,
        },
    },
    {
        title: "รายละเอียด",
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        onCell: () => ({
            "key": "ลำดับ",
        }),
        render: (text, record1) => {
            const dataSource = [record1]
            const filteredData = dataSource.filter(record => record.products !== 'land');
            if (record1?.products === 'land') {
                return null;
            }

            const dataNew = () => {
                return (
                    <>
                        <div> เลขสัญญา <b>{record1?.CONTNO ? record1?.CONTNO : null}</b> </div>
                        <div>{record1.SNAM} {record1.NAME1} {record1.NAME2}</div>
                        {
                            record1.BAAB || record1.TYPE || record1.MODEL || record1.MANUYR || record1.REGNO ?
                                <div>
                                    {record1.TYPE ? record1.TYPE + ' ' : null}
                                    {record1.MODEL ? record1.MODEL + ' ' : null}
                                    {record1.BAAB ? record1.BAAB + ' ' : null}
                                    {record1.REGNO ? ' เลขทะเบียน' + ' ' + record1.REGNO : null}
                                </div>
                                : null
                        }
                    </>
                )
            }

            return (
                <div>
                    {dataNew()}
                </div>
            );
        }
    },
    {
        title: "การชำระ",
        dataIndex: "Payment",
        key: "Payment",
        align: "center",
        render: (text, record) => (
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <span>
                            จ่ายขั้นต่ำ : { }
                        </span>
                        {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                    </div>
                    <div>
                        <span>
                            วันที่จ่าย : { }
                        </span>
                        {record.PAYDT ? dayjs(record.PAYDT).format("DD-MM-YYYY") : '-'}
                    </div>
                    <div>
                        <span>
                            ยอดที่จ่าย : { }
                        </span>
                        {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' ' + 'บาท' : '-'}
                    </div>
                </div>
            </>
        ),
    },
    {
        title: "สถานะการจ่าย",
        dataIndex: "ApprovalStatus",
        key: "ApprovalStatus",
        align: "center",
        render: (text, record) => {
            const netPay = Number(record.NETPAY);
            const payLimitAmt = Number(record.PAYLIMITAMT);
            if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                const check = netPay >= payLimitAmt
                return (
                    <>
                        {check ? <Tag style={{ background: 'green', color: 'white', fontSize: '16px', padding: 5 }} > จ่ายแล้ว</Tag > : "-"}
                    </>
                )
            }
        }
    },
    {
        title: "สถานะสัญญา",
        dataIndex: "ApprovalStatus",
        key: "ApprovalStatus",
        align: "center",
        render: (text, record) => {
            // console.log("record", record)
            const netPay = Number(record.NETPAY);
            const payLimitAmt = Number(record.PAYLIMITAMT);
            if (!isNaN(netPay) && !isNaN(payLimitAmt)) {
                const check = netPay >= payLimitAmt
                return (
                    <>

                        {
                            record?.flagTellAun ? <Tag style={{ background: "#a09df0", color: 'white', fontSize: '16px', padding: 5 }} >เร่งรัดนอก</Tag>
                                : record?.flagClose ? <Tag style={{ background: "#e6283beb", color: 'white', fontSize: '16px', padding: 5 }}>เคสปิด</Tag>
                                    : record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                        : record?.flagTellAun && record?.flagOffer ? <Tag style={{ background: "blue", color: 'white', fontSize: '16px', padding: 5 }}>ส่งการตลาด</Tag>
                                            : "-"
                        }
                    </>
                )
            }
        }
    },
]