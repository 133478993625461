import React from "react";
import { Card, Tag, Col, } from "antd";

const Cars = () => {

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: "10%",
      render: (text, object, key) => key + 1,
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 5,
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "40%",
      render: (text, record1) => {
        return (
          <div>
            รายละเอียด
          </div>
        );
      }
    },
    {
      title: "สถานะสัญญา",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <>
          <Tag style={{ backgroundColor: "pink" }}>
            <b>สัญญาที่ปิดแล้ว</b>
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Card style={{ height: '100vh', }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="text-center">
            <h2><b>รถ</b></h2>
          </div>
        </Col>


      </Card>

    </>
  );
};

export default Cars;
