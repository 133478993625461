import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, notification, Row, Col } from "antd";
import Card from "antd/es/card/Card";

function MainCheckStepCar() {


  return (
    <Card style={{ height: '100vh', }}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <div className="text-center">
          <h2><b>เคสที่โดนระงับ</b></h2>
        </div>
      </Col>


    </Card>

  );
}
export default MainCheckStepCar;
