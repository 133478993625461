
export const other_debtor_columns = [
    {
        title: 'สาขา',
        dataIndex: 'name',
    },
    {
        title: 'เลขเอกสาร',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'วันที่ตั้งหนี้',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
    {
        title: 'ประเภท',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'รหัสชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'รายละเอียด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดหนี้',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดคงเหลือ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันครบกำหนดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'พนักงานติดตาม',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'INPDT',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
];

export const other_debtor_data = [
    {
        key: '1',
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'Jim Green',
        chinese: 98,
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'Joe Black',
        chinese: 98,
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'Jim Red',
        chinese: 88,
        math: 99,
        english: 89,
    },
];

// Debt_Obligation_Schedule
export const Debt_Obligation_Schedule_data = [
    {
        key: '1',
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'Jim Green',
        chinese: 98,
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'Joe Black',
        chinese: 98,
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'Jim Red',
        chinese: 88,
        math: 99,
        english: 89,
    },
];
export const Debt_Obligation_Schedule_columns = [
    {
        title: 'งวดที่',
        dataIndex: 'name',
    },
    {
        title: 'กำหนดชำระ',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'ค่างวด',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
    {
        title: 'ดอกเบี้ย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'เงินต้น',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันดิวแจ้งหนี้',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระดอกเบี้ย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระงเงินต้น',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
];
// Installment_Payment_Schedule
export const Installment_Payment_Schedule_data = [
    {
        key: '1',
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'Jim Green',
        chinese: 98,
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'Joe Black',
        chinese: 98,
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'Jim Red',
        chinese: 88,
        math: 99,
        english: 89,
    },
];
export const Installment_Payment_Schedule_columns = [
    {
        title: 'งวดที่',
        dataIndex: 'name',
    },
    {
        title: 'เงินต้น',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'จำนวนวัน',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
    {
        title: 'วันที่กำหนดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ค่างวด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ดอกเบี้ย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'เงินต้น',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ค่าทวงถาม',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันที่ชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระค่างวด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระดอกเบี้ย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระเงินต้น',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระค่าทวงถาม',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
];
// Payment_Receipt_Schedule
export const Payment_Receipt_Schedule_data = [
    {
        key: '1',
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'Jim Green',
        chinese: 98,
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'Joe Black',
        chinese: 98,
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'Jim Red',
        chinese: 88,
        math: 99,
        english: 89,
    },
];
export const Payment_Receipt_Schedule_columns = [
    {
        title: 'สาขารับชำระ',
        dataIndex: 'name',
    },
    {
        title: 'วันที่ใบรับ',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'เลขที่ใบรับ',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
    {
        title: 'ชำระค่า',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระด้วย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระค่างวด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระดอกเบี้ย',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระเงินต้น',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ส่วนลด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระค่าปรับ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ลดค่าปรับ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระค่าทวงถาม',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ส่วนลดค่าทวงถาม',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ชำระสุทธิ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'สถานะ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันยกเลิก',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'วันที่บันทึก',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ผู้บันทึก',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'เลขอ้างอิง',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
];
// Tracking_Record_Schedule
export const Tracking_Record_Schedule_data = [
    {
        key: '1',
        name: 'MIT',
        chinese: 'พี่อั๋น',
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'UD',
        chinese: 'พี่อั๋น',
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'S4',
        chinese: 'พี่อั๋น',
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'KSM',
        chinese: 'พี่อั๋น',
        math: 99,
        english: 89,
    },
];
export const Tracking_Record_Schedule_columns = [
    {
        title: 'วันที่ติดตาม',
        dataIndex: 'name',
    },
    {
        title: 'ทีมติดตาม',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'บันทึกติดตาม',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
];
// Installment_Deposit_Schedule
export const Installment_Deposit_Schedule_data = [
    {
        key: '1',
        name: 'John Brown',
        chinese: 98,
        math: 60,
        english: 70,
    },
    {
        key: '2',
        name: 'Jim Green',
        chinese: 98,
        math: 66,
        english: 89,
    },
    {
        key: '3',
        name: 'Joe Black',
        chinese: 98,
        math: 90,
        english: 70,
    },
    {
        key: '4',
        name: 'Jim Red',
        chinese: 88,
        math: 99,
        english: 89,
    },
];
export const Installment_Deposit_Schedule_columns = [
    {
        title: 'สาขา',
        dataIndex: 'name',
    },
    {
        title: 'เลขใบรับเงิน',
        dataIndex: 'chinese',
        // sorter: {
        //     compare: (a, b) => a.chinese - b.chinese,
        //     multiple: 3,
        // },
    },
    {
        title: 'วันที่รับเงิน',
        dataIndex: 'math',
        // sorter: {
        //     compare: (a, b) => a.math - b.math,
        //     multiple: 2,
        // },
    },
    {
        title: 'รหัสชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'รายละเอียดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดชำระ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ส่วนลด',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดสุทธิ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'พนักงานเก็บเงิน',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'สถานะ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'หักหนี้แล้ว',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
    {
        title: 'ยอดคงเหลือ',
        dataIndex: 'english',
        // sorter: {
        //     compare: (a, b) => a.english - b.english,
        //     multiple: 1,
        // },
    },
];

export const guarantor_data = [
    {
        garno: 1,
        name: 'test1',
        lastname: 'test1',
        nickname: 'test1',
        relationship: 'พ่อ',
        identification_id: '12345657897645',
        workplace: 'ขอนแก่น',
        phone: '09878787865',
        address: ' 1/24 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40000',
    },
    {
        garno: 2,
        name: 'test2',
        lastname: 'test2',
        nickname: 'test2',
        relationship: 'แม่',
        identification_id: '73737563634829',
        workplace: 'ขอนแก่น',
        phone: '06576464646',
        address: ' 1/24 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40001',
    },
    {
        garno: 3,
        name: 'test3',
        lastname: 'test3',
        nickname: 'test3',
        relationship: 'ป้า',
        identification_id: '98823674326746',
        workplace: 'ขอนแก่น',
        phone: '023747474623',
        address: ' 1/26 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40003',
    },
]
export const history_data = [
    {
        customer_group: 1,
        customer_id: '2342342',
        date_of_birth: '10/10/2567',
        identification_id: '12345657897645',
        workplace: 'ขอนแก่น',
        phone: '0998785675757',
        address: ' 1/24 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40000',
    },
    {
        customer_group: 2,
        customer_id: '2344432',
        date_of_birth: '11/10/2567',
        identification_id: '73737563634829',
        workplace: 'ขอนแก่น',
        phone: '049574763544',
        address: ' 1/24 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40001',
    },
    // {
    //     customer_group: 3,
    //     customer_id: '86757575',
    //     date_of_birth: '12/10/2567',
    //     identification_id: '98823674326746',
    //     workplace: 'ขอนแก่น',
    //     phone: '047553435553',
    //     address: ' 1/26 ถนนมิตรภาพ ตำบลในเมือง อำเเภอเมือง จังหวัดขอนแก่น 40003',
    // },
]

export const columns1 = [
    {
        title: "ลำดับ",
        dataIndex: "index",
        key: 'index',
        align: 'center',
        width: "15%",
        render: (text, object, index) => index + 1
    },
    {
        title: "phone",
        dataIndex: "phone",
        key: 'phone',
        align: 'center',
        width: "50%",
        render: (text, record) => (
            <>{record.phone}</>
        ),
    },
    // {
    //     title: '', // คอลัมน์นี้สำหรับปุ่มขยาย
    //     dataIndex: Table.EXPAND_COLUMN, // ใช้ `Table.EXPAND_COLUMN` เพื่อให้เป็นคอลัมน์ขยาย
    //     key: 'expand',
    //     width: "10%", // ขนาดตามที่ต้องการ
    // },
]



