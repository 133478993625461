import React, { useState, useEffect } from "react";
import SignIn from "./pages/SignIn"
import Main from "./components/layout/Main"

export default function App() {
	// const token = localStorage.getItem("token");
	const [token, setToken] = useState(localStorage.getItem("token"));

	// ฟังก์ชันสำหรับอัปเดต token เมื่อ login สำเร็จ
	const handleLogin = (newToken) => {
		localStorage.setItem("token", newToken); // เก็บ token ลง localStorage
		setToken(newToken); // อัปเดต state
	};

	useEffect(() => {
		// ตรวจสอบ token ใน localStorage (ทำงานเมื่อ mount component)
		const storedToken = localStorage.getItem("token");
		if (storedToken) {
			setToken(storedToken);
		}
	}, []);
	if (!token) {
		return (<><SignIn onLogin={handleLogin} /></>);
	}
	return (
		<>
			<Main />
		</>
	);

}


