import { Col, Row, Spin, Card, Select, Input, Button, Form, Checkbox, InputNumber, DatePicker, Table, message } from 'antd'
import React, { useRef, useState } from 'react'
import { FaPaperPlane, FaPrint } from 'react-icons/fa';
import { DataColumsStatus } from './DataColumn';
import axios from 'axios';
import dayjs from "dayjs";
import { useEffect } from 'react';
import { getchqtran, getexpreal, updateexpreal } from '../mid/all-api';
const style = {
    fontSize: '18px',  // ขนาดตัวอักษรที่ต้องการ
    fontWeight: 'bold' // ถ้าต้องการทำตัวหนา
};

function Main_Call_Team() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);

    const [dataforsearch, setDataForSearch] = useState({});
    const user = localStorage.getItem('username')
    const token = localStorage.getItem("token");
    const conponentPDF = useRef();
    const [counts, setCounts] = useState([
        { key: 0, value: 0 },
        { key: 1, value: 0 },
        { key: 2, value: 0 },
        { key: 3, value: 0 }
    ]);
    const dayCurrent = new Date();
    dayCurrent.setDate(dayCurrent.getDate()); // ลดวันที่ลง 1 วัน
    const year = dayCurrent.getFullYear();
    const month = String(dayCurrent.getMonth() + 1).padStart(2, '0'); // เพิ่ม 1 เพราะเดือนเริ่มต้นจาก 0
    const day = String(dayCurrent.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // เพิ่มเวลา
    const hours = String(dayCurrent.getHours()).padStart(2, '0');
    const minutes = String(dayCurrent.getMinutes()).padStart(2, '0');
    const seconds = String(dayCurrent.getSeconds()).padStart(2, '0');
    const formattedDateNew = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    useEffect(() => {
        // loadData()
        const fetchData = async () => {
            try {
                await loadData(); // รอให้ queryData ทำงานเสร็จ
            } catch (error) {
                console.error("Error loading data:", error); // จัดการข้อผิดพลาด
            }
        };

        fetchData(); // เรียกใช้งานฟังก์ชัน fetchData
    }, [])

    const handleSubmit = () => {
        console.log("Submit", dataforsearch)
        sendData()
    };

    const loadData = async () => {
        setLoading(true)
        await axios.get(getexpreal)
            .then((res) => {
                console.log("resasdasdasdasda", res.data)
                form.setFieldsValue({
                    inputDate: dayjs(res?.data?.inputDate),
                    exprealL: parseFloat(res?.data?.exprealL),
                    exprealH: parseFloat(res?.data?.exprealH),
                });
                setDataForSearch({
                    inputDate: dayjs(res?.data?.inputDate),
                    exprealL: parseFloat(res?.data?.exprealL),
                    exprealH: parseFloat(res?.data?.exprealH),
                })
                setLoading(false)
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const formatToISOWithOffset = (date) => {
        if (!date) return null; // ตรวจสอบว่ามีค่า date หรือไม่
        const offsetMinutes = 7 * 60; // UTC+7 เป็น 7 ชั่วโมงในหน่วยนาที
        const utcDate = new Date(date); // สร้าง Date object
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes); // เพิ่ม offset เวลา
        return utcDate.toISOString().replace("Z", "+07:00"); // แปลงเป็น ISO8601 และแทน "Z" ด้วย "+07:00"
    };

    const sendData = async () => {
        setLoading(true)
        const headers = {
            Authorization: `Bearer ${token}`,
            Menu: JSON.stringify("25"),
        };
        const param = {
            ...dataforsearch,
            inputDate: formatToISOWithOffset(dataforsearch.inputDate),
            idSetExpreal: 1,
            inputBy: user
        }
        console.log("param", param)
        await axios.put(updateexpreal, param, { headers })
            .then((res) => {
                console.log("res.data", res.data)
                message.success(
                    'บันทึกสำเร็จ!',
                    3,
                    () => {
                        console.log('Message closed!');
                    },
                    {
                        style: style  // ใช้ Inline Style
                    }
                );
                setLoading(false)
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleChange = (key, value) => {
        setDataForSearch((prev) => ({ ...prev, [key]: value }));
    };

    const countoptions = () => {
        const options = [
            { value: 0, label: `รายงานตรวจสอบสถานะของทีมโทรทวงถาม` },
            // { value: 1, label: `จ่ายแล้ว` },
            // { value: 2, label: `ยังไม่จ่าย` },
            // { value: 3, label: `จ่ายยังไม่ถึงขั้นต่ำ` }
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>

        ));
    }

    const columns = DataColumsStatus.map((col) => {
        return {
            ...col,
        }
    })

    return (
        <Card
            style={{
                height: '100vh',
                // backgroundColor: '#f0f0f0'
            }}
        >
            <div className="responsive-table">
                <Row gutter={32} justify={'center'}>
                    <Col>
                        <Spin spinning={loading} size="large" tip=" Loading... ">
                            <Col>
                                <div className="text-center">
                                    <h2>รายงานตรวจสอบสถานะ</h2>
                                </div>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Row
                        justify={'center'}
                        className="mains"
                    >
                        <div style={{
                            margin: 2,
                            textAlign: 'center',
                        }}>
                            <Select
                                defaultValue={<b>ทั้งหมด</b>}
                                style={{
                                    // width: '100%',
                                    height: '40px'
                                }}
                                value={selectedValue}
                                onChange={(value) => handleChange('selectedValue', value)}
                            >
                                {countoptions()}
                            </Select>
                        </div>
                    </Row>
                    <Row ref={conponentPDF} justify="space-around" align="middle" gutter={[16, 16]} style={{ marginTop: '15px' }}>
                        <Col span={6}>
                            <Form.Item
                                colon={false} // ลบเครื่องหมาย ":" ด้านขวาของ label
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center', // จัดให้อยู่กลางคอลัมน์
                                    gap: '8px', // เพิ่มระยะห่างระหว่าง label และ Checkbox
                                    marginBottom: 0,
                                }}
                                onChange={(e) => handleChange('allTeamsCall', e.target.checked)}
                            >
                                <span>ทุกทีมโทร : </span>
                                <Checkbox>เลือก</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <span>วันที่ประมวลผลล่าสุด : </span>
                                <Form.Item style={{ marginBottom: 0 }} name="inputDate">

                                    <DatePicker
                                        // format={'DD-MM-YYYY'} // รูปแบบวันที่ที่แสดง
                                        onChange={(date, dateString) => handleChange('inputDate', dateString)  // แปลงเป็น string ของวันที่
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <span>ค้างชำระ : </span>
                                    <span>งวดที่ : </span>
                                    <Form.Item name="exprealL" noStyle>
                                        <InputNumber
                                            min={-1}
                                            placeholder="-1"
                                            onChange={(value) => handleChange('exprealL', value)}
                                        />
                                    </Form.Item>
                                    <span>ถึง : </span>
                                    <Form.Item name="exprealH" noStyle>
                                        <InputNumber
                                            min={1}
                                            placeholder="99"
                                            onChange={(value) => handleChange('exprealH', value)}
                                        />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    <span>การรายงาน : </span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column', // จัดตำแหน่ง Checkbox ในแนวตั้ง
                                            gap: '4px', // เพิ่มช่องว่างระหว่าง Checkbox
                                        }}
                                    >
                                        <Checkbox onChange={(e) => handleChange('includeSeized', e.target.checked)}>รวมรถยึด</Checkbox>
                                        <Checkbox onChange={(e) => handleChange('excludeSeized', e.target.checked)}>ไม่รวมรถยึด</Checkbox>
                                    </div>
                                    <Button
                                        type="primary" // ใช้สไตล์ปุ่ม Ant Design
                                        onClick={handleSubmit}
                                    >
                                        <FaPaperPlane size={20} />
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    style={{ marginTop: "5px" }}
                    scroll={{
                        y: 655,
                    }}
                    // expandable={expandableConfig}
                    // dataSource={arrayTable}
                    columns={columns}
                >
                </Table>
            </div>
        </Card>
    )
}

export default Main_Call_Team