import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Table, Spin, Space, Card, Divider, Select, Tag, message, Form, Checkbox, } from "antd";
import { SearchOutlined, } from "@ant-design/icons";
import axios from "axios";
import ModalInstallment from "../check_info_PG_RE/modals/ModalInstallment";
import BillExpedite from "./modals/BillExpedite";
import "../css/responsive-table.css";
import { DataColumsCar } from "./dataColumn"
import { FaDatabase, FaFileInvoiceDollar, FaMinus, FaPaperPlane, FaPlus, FaPrint, FaTasks } from "react-icons/fa";
// import "../css/Media.css";
import { results } from "../mid/all-api";

const Cars_Expedite = () => {
  const idPosition = localStorage.getItem("idSep");
  const [arrayTable, setArrayTable] = useState(); // ข้อมูลใน Table
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalInfoCar, setIsModalInfoCar] = useState(false);
  const [dataInstallment, setDataInstallment] = useState();
  const [dataInfoCar, setDataInfoCar] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [countclose, setCountClose] = useState();
  const [countMk, setCountMk] = useState();
  const [countTellAun, setCountTellAun] = useState();
  const [countclosedata, setCountClosedata] = useState();
  const [countMkdata, setCountMkdata] = useState();
  const [countTellAundata, setCountTellAundata] = useState();
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [CountsBukup, setCountsBukup] = useState()
  const [selectedValueType, setSelectedValueType] = React.useState(1);
  const [counts, setCounts] = useState([
    { key: 0, value: 0 },
    { key: 1, value: 0 },
    { key: 2, value: 0 },
    { key: 3, value: 0 }
  ]);
  const [listbeforeSelect, setListBeforeSelect] = useState()
  const [changecheck, setChangeCheck] = useState()
  const [queryContno, setQueryContno] = useState('');
  const [queryContno1, setQueryContno1] = useState(2);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const user = localStorage.getItem('username')
  const nicknameSalcod = localStorage.getItem('nicknameSalcod')
  const [checkLoadData, setCheckLoadData] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isFloatingVisible, setIsFloatingVisible] = useState(false);
  const [dataforsearch, setDataForSearch] = useState({
    takebrotheraun: false,
    takethecasetoclose: false,
    excludingseizedvehicles: false,
  });

  useEffect(() => {
    if (!dataforsearch.excludingseizedvehicles &&
      !dataforsearch.takebrotheraun &&
      !dataforsearch.takethecasetoclose) {
    } else if (!dataforsearch.takethecasetoclose) {

    }
  }, [dataforsearch, selectedValue]);
  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 600);
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const onSelectType = (value) => {
    console.log("valurrereee", value)
    setSelectedValueType(value);
    setSelectedValue("")
  };

  // ดึงสัญญา
  const onQueryContno = async () => {
    setQueryContno("")
    setDataForSearch({
      takebrotheraun: false,
      takethecasetoclose: false,
      excludingseizedvehicles: false,
    });
    var data_type
    if (selectedValueType === 1) {
      data_type = 'lsfhp'
    } else if (selectedValueType === 2) {
      data_type = 'vsfhp'
    } else if (selectedValueType === 3) {
      data_type = 'rpsl'
    } else if (selectedValueType === 4) {
      data_type = 'sfhp'
    }
    // { DATA_TYPE: 'rpsl' }, 3
    // { DATA_TYPE: 'vsfhp' }, 2
    // { DATA_TYPE: 'lsfhp' }, 1
    // { DATA_TYPE: 'sfhp' }, 8

    const papams = {
      BILLCOLL: user,
      nicknameSalcod: nicknameSalcod,
      DATA_TYPE: data_type

    }
    setLoading(true);
    await axios.post(results, papams)
      .then(res => {
        let i = 1;
        var news = []

        if (res.data) {
          const newData = res.data.map((item) => ({
            ...item,
            key: i++,
          }));
          // เรียงวันที่
          newData.sort((a, b) => {
            const contnoA = a.CONTNO || "";
            const contnoB = b.CONTNO || "";

            if (contnoA < contnoB) return -1;
            if (contnoA > contnoB) return 1;
            return 0; // กรณีที่เท่ากัน

            // หาก CONTNO เท่ากัน ให้เรียงตามวันที่
            // const dateA = new Date(a.PAYDT || a.TEMPDATE);
            // const dateB = new Date(b.PAYDT || b.TEMPDATE);
            // return dateB - dateA; // เรียงวันที่จากล่าสุด
          });

          let countData = []; // ใช้เก็บ countData ใหม่
          countData = [
            { key: 0, value: newData?.length },
            { key: 1, value: newData.filter(record => record.NETPAY !== 0 && record.NETPAY >= record.PAYLIMITAMT).length },
            { key: 2, value: newData.filter(record => record.NETPAY !== 0 && record.NETPAY < record.PAYLIMITAMT).length },
            { key: 3, value: newData.filter(record => record.NETPAY === 0 || record.NETPAY === null).length },
          ];

          var aun = []
          var close = []
          var mk = []

          res.data.map((item) => {
            if (item?.flagTellAun) {
              aun.push(item)
            } if (item?.flagClose) {
              close.push(item)
            } if (item?.flagOffer) {
              mk.push(item)
            }
          });
          setListBeforeSelect(newData)
          setCountTellAun(aun.length)
          setCountClose(close.length)
          setCountMk(mk.length)
          setChangeCheck(newData)
          setCountTellAundata(aun)
          setCountClosedata(close)
          setCountMkdata(mk)
          setCounts(countData);
          setCountsBukup(countData);
          setArrayTable(newData);
          setDataArr(newData)
          setCheckLoadData(1)
          setSelectedValue(0);
          setLoading(false);
        } else {
          setArrayTable([]);
          message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const onQuery = () => {
    if (queryContno === '') {
      setArrayTable(dataArr)
    }
    let lowerQuery = queryContno.toLowerCase();
    let result = changecheck.filter(
      (item) =>
        (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
        (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
        (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
        (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
        (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery)) || //เลขคัสซี
        // (item.TYPE && item.TYPE.toLowerCase().includes(lowerQuery)) ||    //ยี่ห้อ
        // (item.MODEL && item.MODEL.toLowerCase().includes(lowerQuery)) ||   //รุ่น
        // (item.BAAB && item.BAAB.toLowerCase().includes(lowerQuery)) ||   //แบบ
        (item.TELP && item.TELP.toLowerCase().includes(lowerQuery))   //เบอร์โทร
    );

    setArrayTable(result);
  };

  // ช่องกรอก
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    console.log("inputValue", inputValue);
    setQueryContno(inputValue);
    if (inputValue === '') {
      setArrayTable(changecheck)
    }
  };
  // ฟังก์ชันจับเหตุการณ์กด Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
    }
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const handleChange = (key, value) => {
    setDataForSearch((prev) => ({
      ...prev,
      [key]: value, // อัปเดตค่าของ key ที่เปลี่ยนแปลง
    }));
    const { excludingseizedvehicles, takebrotheraun, takethecasetoclose } = { ...dataforsearch, [key]: value };
    if (!excludingseizedvehicles && !takebrotheraun && !takethecasetoclose) {
      setCounts(CountsBukup)
      setArrayTable(changecheck)
    } else {
      setCounts(CountsBukup)
      // รวมข้อมูลที่มีการติ๊ก (true)
      let combinedData = [];

      if (takebrotheraun) {  // เคสเร่งรัด
        combinedData = [...combinedData, ...countTellAundata];
      }

      if (takethecasetoclose) {  // เคสปิด
        combinedData = [...combinedData, ...countclosedata];
      }

      if (excludingseizedvehicles) {  // เคสการตลาดส่ง
        combinedData = [...combinedData, ...countMkdata];
      }
      // ลบค่าที่ซ้ำกันใน combinedData
      const uniqueCombinedData = Array.from(new Set(combinedData));
      // เช็คค่าที่เหมือนกันระหว่าง changecheck และ uniqueCombinedData
      const matchingData = changecheck.filter(
        item => !uniqueCombinedData?.some(uniqueItem => uniqueItem?.CONTNO === item?.CONTNO)
      );
      const mergeDuplicateKeys = (array) => {
        // ใช้ reduce เพื่อสร้างออบเจ็กต์ที่รวมค่าเฉพาะ key
        const mergedData = array.reduce((acc, item) => {
          if (acc[item.key]) {
            // ถ้า key ซ้ำ อัปเดต value
            acc[item.key].value = item.value;
          } else {
            // ถ้า key ไม่ซ้ำ เพิ่มออบเจ็กต์เข้าไป
            acc[item.key] = { ...item };
          }
          return acc;
        }, {});

        // แปลงกลับเป็น array
        return Object.values(mergedData);
      };
      const countData = [
        ...counts,
        { key: selectedValue, value: matchingData?.length },
      ]
      const result = mergeDuplicateKeys(countData);
      setCounts(result)
      setArrayTable(matchingData);

    }
  };

  const onSelect = (value) => {
    setQueryContno("")
    setSelectedValue(value);
    setDataForSearch({
      takebrotheraun: false,
      takethecasetoclose: false,
      excludingseizedvehicles: false,
    });

    let filteredArrays = [];
    var aun = []
    var close = []
    var mk = []

    if (value === 0) {
      filteredArrays = listbeforeSelect
      setArrayTable(filteredArrays);
    } else if (value === 1) {
      // ถ้า NETPAY !== 0 && NETPAY >= PAYLIMITAMT  
      filteredArrays = listbeforeSelect?.filter(record => record.NETPAY !== 0 && record.NETPAY >= record.PAYLIMITAMT);
      setArrayTable(filteredArrays);
    } else if (value === 2) {
      // ถ้า NETPAY !== 0 && NETPAY < PAYLIMITAMT
      filteredArrays = listbeforeSelect?.filter(record => record.NETPAY !== 0 && record.NETPAY !== null && record.NETPAY < record.PAYLIMITAMT);
      setArrayTable(filteredArrays);
    } else if (value === 3) {
      filteredArrays = listbeforeSelect?.filter(record => record.NETPAY === 0 || record.NETPAY === null);
      setArrayTable(filteredArrays);
    }
    filteredArrays.map((item) => {
      if (item?.flagTellAun) {
        aun.push(item)
      } if (item?.flagClose) {
        close.push(item)
      } if (item?.flagOffer) {
        mk.push(item)
      }
    });

    // console.log("filteredA---------rel", filteredArrays)
    setChangeCheck(filteredArrays)
    setCountTellAun(aun.length)
    setCountClose(close.length)
    setCountMk(mk.length)
    setCountTellAundata(aun)
    setCountClosedata(close)
    setCountMkdata(mk)
  };

  const countoptionsType = () => {
    const options = [
      // { value: 0, label: `ทั้งหมด (${countstype[0]?.value ?? 0})` },
      { value: 1, label: `บัญชี 1` },
      { value: 2, label: `บัญชี 2` },
      { value: 3, label: `บัญชี 3` },
      { value: 4, label: `บัญชี 8` },
    ];

    return options.map((option) => (
      <Select.Option key={option.value} value={option.value}>
        <b>{option.label}</b>
      </Select.Option>
    ));
  }

  const countoptions = () => {
    const options = [
      { value: 0, label: `ทั้งหมด (${counts[0]?.value})` },
      { value: 1, label: `จ่ายแล้ว (${counts[1]?.value})` },
      { value: 2, label: `จ่ายยังไม่ถึงขั้นต่ำ (${counts[2]?.value})` },
      { value: 3, label: `ยังไม่จ่าย (${counts[3]?.value})` },
    ];

    return options.map((option) => (
      <Select.Option key={option.value} value={option.value}>
        <b>{option.label}</b>
      </Select.Option>
    ));




  }

  const expend = ({ item }) => {
    console.log("item", item)
    return (
      <>
        {/* <Button
          title="ติดตามค่างวด"
          onClick={() => {
            setDataInfoCar(record.CONTNO);
            setIsModalInfoCar(true);
            setDataSend(record);
          }}
        >
          <FaFileInvoiceDollar size={20} />
        </Button> */}
      </>
    )
  }

  const expandableConfig = queryContno1 === 2 ? {
    expandedRowRender: isMobile
      ? () => null // บนมือถือไม่แสดง expandable row
      : (record) => (
        <div
          className={`floating-buttons ${isFloatingVisible ? 'visible' : 'hidden'}`}
        >
          <Space size="small">
            <Button
              title="ติดตามค่างวด"
              onClick={() => {
                setDataInfoCar(record.CONTNO);
                setIsModalInfoCar(true);
                setDataSend(record);
              }}
            >
              <FaFileInvoiceDollar size={20} />
            </Button>
            <Button
              title="ตารางการผ่อน"
              onClick={() => {
                setDataInstallment(record);
                setIsModalInstallment(true);
              }}
            >
              <FaTasks size={20} />
            </Button>
          </Space>
        </div>
      ),
    expandIcon: ({ expanded, onExpand, record }) =>
      record?.flagClose ? null : (
        <>
          {expanded ? (
            <FaMinus
              onClick={(e) => {
                onExpand(record, e);
                if (isMobile) setIsFloatingVisible(false);
              }}
              style={{
                cursor: 'pointer',
                ...(isMobile && { float: 'left', marginRight: '10px' }) // ชิดซ้ายในมือถือ
              }}
            />
          ) : (
            <FaPlus
              onClick={(e) => {
                onExpand(record, e);
                if (isMobile) setIsFloatingVisible(true);
              }}
              style={{
                cursor: 'pointer',
                ...(isMobile && { float: 'left', marginRight: '10px' }) // ชิดซ้ายในมือถือ
              }}
            />
          )}
          {isMobile && expanded && (
            <div
              className={`floating-buttons ${isFloatingVisible ? 'visible' : 'hidden'}`}
            >
              <Space size="small">
                <Button
                  title="ติดตามค่างวด"
                  onClick={() => {
                    setDataInfoCar(record.CONTNO);
                    setIsModalInfoCar(true);
                    setDataSend(record);
                  }}
                >
                  <FaFileInvoiceDollar size={20} />
                </Button>
                <Button
                  title="ตารางการผ่อน"
                  onClick={() => {
                    setDataInstallment(record);
                    setIsModalInstallment(true);
                  }}
                >
                  <FaTasks size={20} />
                </Button>
              </Space>
            </div>
          )}
        </>
      ),

  }
    :
    null
    ;
  const expandableConfigMoblie = queryContno1 === 2 ? {
    expandedRowRender: null,
    expandIcon: ({ expanded, onExpand, record }) =>
      record?.flagClose ? null : (
        <>
          {isMobile && (
            <div
              className={`floating-buttons ${isMobile ? 'visible' : 'hidden'}`}
            >
              <Space size="small">
                <Button
                  title="ติดตามค่างวด"
                  onClick={() => {
                    setDataInfoCar(record.CONTNO);
                    setIsModalInfoCar(true);
                    setDataSend(record);
                  }}
                >
                  <FaFileInvoiceDollar size={20} />
                </Button>
                <Button
                  title="ตารางการผ่อน"
                  onClick={() => {
                    setDataInstallment(record);
                    setIsModalInstallment(true);
                  }}
                >
                  <FaTasks size={20} />
                </Button>
              </Space>
            </div>
          )}
        </>
      ),

  }
    :
    null
    ;


  const handleChangeStatus = (data, status) => {
    console.log("TEST", data, status);
    const result = dataArr.map((item) => {
      if (item.CONTNO === data.CONTNO) {
        return { ...data };
      } else {
        return { ...item };
      }
    });
    setDataArr(result);
    if (status === "reset") {
      setArrayTable(result);
    } else {
      const arr = result.filter((item) =>
        item.ApprovalStatus === status
      );
      setArrayTable(arr);

    }
  };
  const columns = DataColumsCar.map((col) => {
    if (col.dataIndex === 'key') {
      return {
        ...col,
        render: (text, record, index) => {
          // เช็คว่าเป็นมือถือหรือไม่
          return isMobile ? <p style={{ padding: '20px' }} /> : index + 1; // แสดง '-' บนมือถือ, แสดงลำดับตาม index บน Desktop
        },
      };
    }
    return {
      ...col,
    }
  })

  return (
    <div>
      <Card style={{ height: '100vh', fontSize: '20px' }}      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="text-center">
            <h2>พอร์ต</h2>
          </div>
        </Col>
        <Spin spinning={loading} size="large" tip="Loading..." >
          <div className="responsive-table">
            <Row justify={'center'} style={{ marginBottom: 10 }}>
              <div style={{ margin: 2, textAlign: 'center' }}>
                <span>เลือกบัญชี : </span>
                <Select
                  // defaultValue={}
                  style={{
                    width: 280,
                    height: '40px'
                  }}
                  value={selectedValueType}
                  onChange={onSelectType}
                >
                  {countoptionsType()}
                </Select>
              </div>
              <div style={{ margin: 2, textAlign: 'center' }}>
                <Button
                  style={{ marginRight: "10px", height: '40px', color: 'white', background: '#302e94f7' }}
                  onClick={onQueryContno}
                >
                  <FaDatabase />
                  <b>ดึงข้อมูล</b>
                </Button>
              </div>
            </Row>
            <Row
              justify={'center'}
              className="mains"
              align="middle"
              style={{ textAlign: 'center', width: '100%' }} // เพิ่มการจัดกึ่งกลาง
            >
              <div style={{ margin: 2, textAlign: 'center' }}>
                <span>เลือกประเภท : </span>
                {checkLoadData === 0 ?
                  <>
                    <Select
                      disabled
                      defaultValue={0}
                      style={{
                        width: 280,
                        height: '40px'
                      }}
                      value={selectedValue}
                      onChange={onSelect}
                    >
                      {countoptions()}
                    </Select>
                  </>
                  :
                  <>
                    <Select
                      defaultValue={0}
                      style={{
                        width: 280,
                        height: '40px'
                      }}
                      value={selectedValue}
                      onChange={onSelect}
                    >
                      {countoptions()}
                    </Select>
                  </>
                }

              </div>
              <div style={{ margin: 2, textAlign: 'center' }}>
                {checkLoadData === 0 ?
                  <>
                    <Input
                      disabled
                      style={{ width: "280px", height: '40px' }}
                      placeholder="ค้นหาเลขที่สัญญา"
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      name="search"
                      id="search"
                    />
                  </>
                  :
                  <>
                    <Input
                      style={{ width: "280px", height: '40px' }}
                      placeholder="ค้นหาเลขที่สัญญา"
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      name="search"
                      id="search"
                    />
                  </>
                }
              </div>
              <div style={{ margin: 2, textAlign: 'center' }}>
                <Button
                  style={{ marginRight: "10px", height: '40px', width: "50px" }}
                  onClick={(e) => onQuery(e)}
                >
                  <SearchOutlined />
                </Button>
              </div>
              <div>
                <Col span={24}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <div
                        style={{
                          gap: '8px',
                        }}
                      >
                        <Checkbox
                          onChange={(e) => handleChange("takebrotheraun", e.target.checked)}
                          checked={dataforsearch.takebrotheraun} // แสดงสถานะปัจจุบัน
                        >
                          ไม่เอาเร่งรัดนอก ({countTellAun || 0})
                        </Checkbox>

                        <Checkbox
                          onChange={(e) => handleChange("takethecasetoclose", e.target.checked)}
                          checked={dataforsearch.takethecasetoclose} // แสดงสถานะปัจจุบัน
                        >
                          ไม่เอาเคสปิด ({countclose || 0})
                        </Checkbox>

                        <Checkbox
                          onChange={(e) => handleChange("excludingseizedvehicles", e.target.checked)}
                          checked={dataforsearch.excludingseizedvehicles} // แสดงสถานะปัจจุบัน
                        >
                          ไม่เอาสัญญาที่ส่งการตลาด ({countMk || 0})
                        </Checkbox>

                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </div>
            </Row>


            <Col span={24}>
              <Row>
                <Table
                  key={arrayTable?.length}
                  style={{ marginTop: "5px" }}
                  scroll={{
                    y: 620,
                  }}
                  expandable={isMobile ? expandableConfigMoblie : expandableConfig}
                  dataSource={arrayTable}
                  columns={columns}
                ></Table>
              </Row>

            </Col>
          </div>
        </Spin>

      </Card>
      {
        isModalInfoCar ?
          <BillExpedite
            open={isModalInfoCar}
            close={setIsModalInfoCar}
            continoBill={dataInfoCar}
            data={dataSend}
            onChangeStatus={handleChangeStatus}
            status={selectedValue}
          />
          : null}
      {
        isModalInstallment ?
          <ModalInstallment
            open={isModalInstallment}
            close={setIsModalInstallment}
            data={dataInstallment}
          />
          : null
      }

    </div>
  );
};

export default Cars_Expedite;
