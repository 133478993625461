
import { Button, Col, Input, Row, Table, Spin, Space, Card, Divider, Select, Tag, message, } from "antd";
import dayjs from "dayjs";
const currencyFormatOne = (amount) => {
    if (amount) {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
        return 0;
    }
};

export const DataColumsStatus = [
    {
        title: `เลขที่สัญญา\nเบอร์โทร`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",

        render: (text, record) => {
            return (
                <>
                    {record.CONTNO}<br />
                    {record.TELP}

                </>
            )
        }
    },
    {
        title: "ชื่อลูกค้า",
        dataIndex: "NAME",
        key: "NAME",
        align: "center",
        render: (text, record) => {
            return (
                <>
                    {record.SNAM}<n />
                    {record.NAME1}<n />
                    {record.NAME2}

                </>
            )
        }
    },
    {
        title: `สินค้า\nทะเบียน`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            return (
                <>
                    {record.TYPE}<br />
                    {record.MODEL}<br />
                    {record.BAAB}

                </>
            )
        }
    },
    {
        title: `ยอดผ่อน\nทีมนออก`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `งวดละ\nวันดิวงวด`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ค้างงวด\nจาก - ถึง`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `เงินค้างงวด\nค้าค่าปรับ`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ชำระล่าสุด`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ขาดการติดต่อ`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ลูกหนี้คงเหลือ`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ชำระขั้นต่ำ`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `ยอดต่ำกว่าเป้า`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },
    {
        title: `เกรด`,
        dataIndex: "CONTNO",
        key: "CONTNO",
        align: "center",
        // width: "8%",
        render: (text, record) => {
            // console.log("record", record)
        }
    },

]