import { Col, Row, Spin, Card, Table, Tag, Space, Input, Button, Select, Form, Checkbox, message } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import React, { useEffect } from 'react'
import { useState } from 'react';
import { SearchOutlined, } from "@ant-design/icons";
import { FaDatabase, FaSortDown, FaSortUp, } from "react-icons/fa";
import { results } from '../mid/all-api';


const MainNew = () => {
    const [dataArray, setDataArray] = useState()
    const [loading, setLoading] = useState(false);
    const [queryContno, setQueryContno] = useState("");
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedValueType, setSelectedValueType] = useState(1);
    const [expandedRows, setExpandedRows] = useState([]);
    const user = localStorage.getItem('username')
    const nicknameSalcod = localStorage.getItem('nicknameSalcod')
    const [listbeforeSelect, setListBeforeSelect] = useState();
    const [countclose, setCountClose] = useState();
    const [countMk, setCountMk] = useState();
    const [countTellAun, setCountTellAun] = useState();
    const [countclosedata, setCountClosedata] = useState();
    const [countMkdata, setCountMkdata] = useState();
    const [countTellAundata, setCountTellAundata] = useState();
    const [changecheck, setChangeCheck] = useState()
    const [CountsBukup, setCountsBukup] = useState()
    const [checkLoadData, setCheckLoadData] = useState(0);
    const [dataforsearch, setDataForSearch] = useState({
        takebrotheraun: false,
        takethecasetoclose: false,
        excludingseizedvehicles: false,
    });

    const [counts, setCounts] = useState([
        { key: 0, value: 0 },
        { key: 1, value: 0 },
        { key: 2, value: 0 },
        { key: 3, value: 0 },
        { key: 4, value: 0 },
    ]);

    // ดึงสัญญา
    const onQueryContno = async () => {
        setDataForSearch({
            takebrotheraun: false,
            takethecasetoclose: false,
            excludingseizedvehicles: false,
        });
        setQueryContno("")
        var data_type
        if (selectedValueType === 1) {
            data_type = 'lsfhp'
        } else if (selectedValueType === 2) {
            data_type = 'vsfhp'
        } else if (selectedValueType === 3) {
            data_type = 'rpsl'
        } else if (selectedValueType === 4) {
            data_type = 'sfhp'
        }
        // { DATA_TYPE: 'rpsl' }, 3
        // { DATA_TYPE: 'vsfhp' }, 2
        // { DATA_TYPE: 'lsfhp' }, 1
        // { DATA_TYPE: 'sfhp' }, 8

        const papams = {
            BILLCOLL: user,
            nicknameSalcod: nicknameSalcod,
            DATA_TYPE: data_type

        }
        // console.log("dataNewFor Get DATA papams", papams)
        setLoading(true);
        await axios.post(results, papams)
            .then(res => {
                // console.log("resQueryNew", res.data)
                let i = 1;
                if (res.data) {
                    const newData = res.data.map((item) => ({
                        ...item,
                        key: i++,
                    }));
                    const expandedData = [];

                    newData.forEach((ii, index) => {
                        if (ii?.dataList && ii.dataList.length >= 1) {
                            ii.dataList.forEach((listData, listIndex) => {
                                expandedData.push({
                                    ...ii,
                                    ...listData,
                                    key: `${ii.CONTNO || 'unknown'}-${index}-${listIndex}` // สร้าง key เฉพาะ
                                });
                            });
                        } else {
                            expandedData.push({
                                ...ii,
                                key: `${ii.CONTNO || 'unknown'}-${index}`, // กำหนด key เฉพาะสำหรับ ii ที่ไม่มี dataList
                            });
                        }
                    });
                    // console.log("expandedData", expandedData);

                    // เรียงตามเลขสัญญา น้อย => มาก
                    expandedData.sort((a, b) => {
                        const contnoA = a.CONTNO || "";
                        const contnoB = b.CONTNO || "";

                        if (contnoA < contnoB) return -1;
                        if (contnoA > contnoB) return 1;
                        return 0; // กรณีที่เท่ากัน

                        // หาก CONTNO เท่ากัน ให้เรียงตามวันที่
                        // const dateA = new Date(a.PAYDT || a.TEMPDATE);
                        // const dateB = new Date(b.PAYDT || b.TEMPDATE);
                        // return dateB - dateA; // เรียงวันที่จากล่าสุด
                    });

                    let countData = []; // ใช้เก็บ countData ใหม่
                    countData = [
                        { key: 0, value: expandedData?.length },
                        {
                            key: 1, value: expandedData.filter(record => {
                                let mapData = []
                                if (
                                    (record.TMBILL !== "" && record.PAYDT !== null && record.NETPAY >= record?.PAYLIMITAMT) || // เงื่อนไขแรก
                                    (
                                        record.TMBILL !== "" && record.NETPAY >= record?.PAYLIMITAMT && // เงื่อนไขสอง
                                        record?.HDSUM !== 0 && record?.HDTEMPDATE !== null
                                    )
                                ) { mapData.push(record); }
                                // Debug: ดูว่า mapData มีข้อมูลหรือไม่
                                return mapData.length > 0;
                            }).length
                        }, // เข้าค่างวด
                        {
                            key: 2, value: expandedData.filter(record => record?.HDSUM !== 0 && record?.HDTEMPDATE).length
                        },  // รับฝาก 
                        { key: 3, value: expandedData.filter(record => record.TMBILL !== "" && record.PAYDT !== null && (record.NETPAY < record?.PAYLIMITAMT)).length },// เข้าค่างวดแต่ไม่ถึงขั้นต่ำ
                        { key: 4, value: expandedData.filter(record => record?.TMBILL === "" && record.NETPAY === 0 && record?.PAYDT === null && record?.HDSUM === 0 && record?.HDTEMPDATE === null).length },// ยังไม่มีการจ่าย
                    ];

                    var aun = []
                    var close = []
                    var mk = []
                    expandedData.map((item) => {
                        if (item?.flagTellAun) {
                            aun.push(item)
                        } if (item?.flagClose) {
                            close.push(item)
                        } if (item?.flagOffer) {
                            mk.push(item)
                        }
                    });
                    setListBeforeSelect(expandedData)
                    setCountTellAun(aun.length)
                    setCountClose(close.length)
                    setCountMk(mk.length)
                    setChangeCheck(expandedData)
                    setCountTellAundata(aun)
                    setCountClosedata(close)
                    setCountMkdata(mk)
                    setCounts(countData);
                    setCountsBukup(countData);
                    setDataArray(expandedData);
                    setCheckLoadData(1)
                    setSelectedValue(0);
                    setLoading(false);
                } else {
                    setDataArray([]);
                    message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return 0;
        }
    };

    const handleChange = (key, value) => {
        setDataForSearch((prev) => ({
            ...prev,
            [key]: value, // อัปเดตค่าของ key ที่เปลี่ยนแปลง
        }));

        const { excludingseizedvehicles, takebrotheraun, takethecasetoclose } = { ...dataforsearch, [key]: value };
        if (!excludingseizedvehicles && !takebrotheraun && !takethecasetoclose) {
            setCounts(CountsBukup)
            setDataArray(changecheck)
        } else {
            setCounts(CountsBukup)
            // รวมข้อมูลที่มีการติ๊ก (true)
            let combinedData = [];

            if (takebrotheraun) {  // เคสเร่งรัดภาคสนาม
                combinedData = [...combinedData, ...countTellAundata];
            }

            if (takethecasetoclose) {  // เคสที่ปิดไปแล้ว
                combinedData = [...combinedData, ...countclosedata];
            }

            if (excludingseizedvehicles) {  // เคสที่การตลาดเอาไปเสนอ
                combinedData = [...combinedData, ...countMkdata];
            }
            //หาค่าซ้ำ
            const uniqueCombinedData = Array.from(new Set(combinedData));
            // ข้อมูลที่ซ้ำกัน
            const matchingData = changecheck.filter(
                item => !uniqueCombinedData?.some(uniqueItem => uniqueItem?.CONTNO === item?.CONTNO)
            );

            const mergeDuplicateKeys = (array) => {
                // ใช้ reduce เพื่อสร้างออบเจ็กต์ที่รวมค่าเฉพาะ key
                const mergedData = array.reduce((acc, item) => {
                    if (acc[item.key]) {
                        // ถ้า key ซ้ำ อัปเดต value
                        acc[item.key].value = item.value;
                    } else {
                        // ถ้า key ไม่ซ้ำ เพิ่มออบเจ็กต์เข้าไป
                        acc[item.key] = { ...item };
                    }
                    return acc;
                }, {});

                // แปลงกลับเป็น array
                return Object.values(mergedData);
            };
            const countData = [
                ...counts,
                { key: selectedValue, value: matchingData?.length },
            ]
            // เก็บค่าจาก mergeDuplicateKeys() เก็บไว้ในตัวแปร result
            const result = mergeDuplicateKeys(countData);

            setCounts(result)
            setDataArray(matchingData);
        }

    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setQueryContno(inputValue);
        if (inputValue === '') {
            setDataArray(changecheck)
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onQuery();  // เรียกฟังก์ชันค้นหาหลังจากกด Enter
        }
    };

    const onQuery = () => {
        console.log("queryContno", queryContno);
        if (queryContno === '') {
            setDataArray(changecheck)
            console.log("Empty query, setting to full data:", changecheck);
        } else {
            let lowerQuery = queryContno.trim().toLowerCase();
            let result = changecheck.filter(
                (item) =>
                    (item.CONTNO && item.CONTNO.toLowerCase().includes(lowerQuery)) || // เลขสัญญา
                    (item.NAME1 && item.NAME1.toLowerCase().includes(lowerQuery)) || // ชื่อ
                    (item.NAME2 && item.NAME2.toLowerCase().includes(lowerQuery)) || // นามสกุล
                    (item.REGNO && item.REGNO.toLowerCase().includes(lowerQuery)) || // ทะเบีย หรือ เลขโฉนด
                    (item.STRNO && item.STRNO.toLowerCase().includes(lowerQuery)) || //เลขคัสซี
                    // (item.TYPE && item.TYPE.toLowerCase().includes(lowerQuery)) ||    //ยี่ห้อ
                    // (item.MODEL && item.MODEL.toLowerCase().includes(lowerQuery)) ||   //รุ่น
                    // (item.BAAB && item.BAAB.toLowerCase().includes(lowerQuery)) ||   //แบบ
                    (item.TELP && item.TELP.toLowerCase().includes(lowerQuery))   //เบอร์โทร
            );
            console.log("result", result)
            setDataArray(result);
        }
    };

    const countoptions = () => {
        const options = [
            { value: 0, label: `ทั้งหมด (${counts[0].value})` },
            { value: 1, label: `เข้าค่างวด  (${counts[1].value})` },
            { value: 2, label: `รับฝากแล้ว (${counts[2].value})` },
            { value: 3, label: `เข้าค่างวดแต่ไม่ถึงขึ้นต่ำ (${counts[3].value})` },
            { value: 4, label: `ยังไม่มีการจ่าย (${counts[4].value})` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }
    const countoptionsType = () => {
        const options = [
            // { value: 0, label: `ทั้งหมด` },
            { value: 1, label: `บัญชี 1` },
            { value: 2, label: `บัญชี 2` },
            { value: 3, label: `บัญชี 3` },
            { value: 4, label: `บัญชี 8` },
        ];

        return options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
                <b>{option.label}</b>
            </Select.Option>
        ));
    }
    const onSelectType = (value) => {
        console.log("valurrereee", value)
        setSelectedValueType(value);
        setSelectedValue("")
        setQueryContno("")
    };

    const onSelect = (value) => {
        console.log("value", value);
        setSelectedValue(value);
        setQueryContno("")
        setDataForSearch({
            takebrotheraun: false,
            takethecasetoclose: false,
            excludingseizedvehicles: false,
        });
        // PAYLIMITAMT การจ่ายขั้นต่ำ 
        // NETPAY จ่ายเข้ามา 
        let filteredArrays = [];
        var aun = []
        var close = []
        var mk = []

        if (value === 0) {
            filteredArrays = listbeforeSelect
            setDataArray(filteredArrays);
        } else if (value === 1) { // เข้าค่างวด
            // ถ้า NETPAY !== 0 && NETPAY >= PAYLIMITAMT  
            filteredArrays = listbeforeSelect?.filter(record => {
                let mapData = []
                if (
                    (record.TMBILL !== "" && record.PAYDT !== null && record.NETPAY >= record?.PAYLIMITAMT) || // เงื่อนไขแรก
                    (
                        record.TMBILL !== "" && record.NETPAY >= record?.PAYLIMITAMT && // เงื่อนไขสอง
                        record?.HDSUM !== 0 && record?.HDTEMPDATE !== null
                    )
                ) { mapData.push(record); }
                // Debug: ดูว่า mapData มีข้อมูลหรือไม่
                return mapData.length > 0;
            })
            // console.log("filteredArrays111", filteredArrays)
            setDataArray(filteredArrays);
        } else if (value === 2) { // รับฝาก
            // ถ้า NETPAY !== 0 && NETPAY < PAYLIMITAMT
            filteredArrays = listbeforeSelect?.filter(record => record?.HDSUM !== 0 && record?.HDTEMPDATE);
            // console.log("filteredArrays222", filteredArrays)
            setDataArray(filteredArrays);
        } else if (value === 3) { // เข้าค่างวดแต่ไม่ถึงขั้นต่ำ
            filteredArrays = listbeforeSelect?.filter(record => record.TMBILL !== "" && record.PAYDT !== null && (record.NETPAY < record?.PAYLIMITAMT));
            // console.log("filteredArrays333", filteredArrays)
            setDataArray(filteredArrays);
        } else if (value === 4) { // ยังไม่มีการจ่ายเข้า
            filteredArrays = listbeforeSelect?.filter(record => record?.TMBILL === "" && record.NETPAY === 0 && record?.PAYDT === null && record?.HDSUM === 0 && record?.HDTEMPDATE === null);
            // console.log("filteredArrays444", filteredArrays)
            setDataArray(filteredArrays);
        }

        filteredArrays.map((item) => {
            if (item?.flagTellAun) {
                aun.push(item)
            } if (item?.flagClose) {
                close.push(item)
            } if (item?.flagOffer) {
                mk.push(item)
            }
        });

        // console.log("filteredA---------rel", filteredArrays)
        setChangeCheck(filteredArrays)
        setCountTellAun(aun.length)
        setCountClose(close.length)
        setCountMk(mk.length)
        setCountTellAundata(aun)
        setCountClosedata(close)
        setCountMkdata(mk)
    };

    const handleToggleExpand = (key) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(key)
                ? prevExpandedRows.filter((rowKey) => rowKey !== key) // ถ้าแถวนี้ถูกขยายอยู่แล้ว, ให้ยุบ
                : [...prevExpandedRows, key] // ถ้ายังไม่ขยาย, ให้ขยาย
        );
    };

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "15%",
            render: (text, object, index) => (
                <td data-label="ลำดับ : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {index + 1}
                </td>
            ),
        },
        {
            title: "เลขสัญญา",
            dataIndex: "CONTNO",
            key: "CONTNO",
            align: 'center',
            render: (text, record, index) => (
                <td data-label="เลขสัญญา : "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        height: "100%"
                    }}
                >
                    {record.CONTNO || "ไม่มีเลขสัญญา"}
                </td>
            ),
        },
        {
            title: "ยอดที่จ่ายเข้า",
            dataIndex: "NETPAY",
            key: "NETPAY",
            align: 'center',
            render: (text, record, index) => (
                // console.log("record", record)
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px",
                            height: "100%",
                            border: "1px solid #ccc", // เส้นกรอบรอบข้อมูล
                            borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                            margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" // เพิ่มเงาให้ดูสวยงาม
                        }}

                    >
                        <div>
                            <span>จ่ายขั้นต่ำ:{" "}</span>
                            {record.PAYLIMITAMT ? currencyFormatOne(record.PAYLIMITAMT) + ' ' + 'บาท' : '-'}
                        </div>
                        <div>
                            <span>วันที่จ่าย:{" "}</span>
                            {record.PAYDT ? dayjs(record.PAYDT).format("DD-MM-YYYY") : '-'}
                        </div>
                        <div>
                            <span>ยอดที่จ่าย:{" "}</span>
                            {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' ' + 'บาท' : '-'}
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: "วันที่รับฝาก",
            dataIndex: "HDTEMPDATE",
            key: "HDTEMPDATE",
            align: 'center',
            render: (text, record, index) => (
                // console.log("record2222", record)
                <>
                    {record.HDTEMPDATE && record.NETPAY ?
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "3px",
                                    height: "100%",
                                    border: "1px solid #ccc", // เส้นกรอบรอบข้อมูล
                                    borderRadius: "8px", // เพิ่มมุมโค้งให้เส้นกรอบ
                                    margin: "8px 0", // ระยะห่างระหว่างแต่ละชุดข้อมูล
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" // เพิ่มเงาให้ดูสวยงาม
                                }}
                                onClick={() => handleToggleExpand(record.key)}>
                                <div>
                                    <span>
                                        วันที่รับฝาก :
                                    </span>
                                    {record.HDTEMPDATE ? ' ' + dayjs(record.HDTEMPDATE).format("DD-MM-YYYY") : '-'}

                                </div>
                                <div>
                                    <span>ยอดรับฝาก : </span>
                                    <span
                                        style={{ cursor: 'pointer', color: 'blue' }}
                                    >
                                        {/* จะเป็นสัญญาที่จ่ายมาแล้ว แต่เกินขั้นต่ำ ซึ่งส่วนเกินอาจจะโดนหักค่าติดตาม */}
                                        {record.HDSUM ? currencyFormatOne(record.HDSUM) + ' บาท' : '-'}
                                    </span>
                                </div>
                                <span style={{ marginLeft: '10px' }}>
                                    {expandedRows.includes(record.key) ? (
                                        <FaSortUp size={20} /> // แสดงลูกศรลงเมื่อขยาย
                                    ) : (
                                        <FaSortDown size={20} /> // แสดงลูกศรขวาเมื่อยุบ
                                    )}
                                </span>
                                {expandedRows.includes(record.key) && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            paddingLeft: '5px',
                                            backgroundColor: '#f9f9f9',
                                        }}
                                    >
                                        <div style={{ textAlign: 'start', paddingLeft: '3px' }}>
                                            <span>
                                                <u>รายการ</u>
                                            </span>
                                            {record?.NETPAY !== 0 ?
                                                <>
                                                    <div style={{ textAlign: 'start', marginTop: '4px' }} key={index}>
                                                        จ่ายเข้า : {record.NETPAY ? currencyFormatOne(record.NETPAY) + ' บาท' : '-'}
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        :
                        '-'
                    }
                </>
            ),
        },
        {
            title: "วันที่จ่าย",
            dataIndex: "PAYDT",
            key: "PAYDT",
            align: 'center',
            render: (text, record, index) => {
                // คำนวณวันที่จ่ายล่าสุดจาก dataList
                const latestPayDate = record?.dataList
                    ?.filter(item => item.PAYDT) // กรองเฉพาะรายการที่มี PAYDT
                    .map(item => new Date(item.PAYDT)) // แปลงเป็น Date object
                    .reduce((latest, current) => (current > latest ? current : latest), new Date(0)); // หา Date ที่ใหม่ที่สุด

                return (
                    <div style={{ textAlign: 'center' }}>
                        <td data-label="วันที่จ่าย : "
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "8px",
                                height: "100%"
                            }}
                        >
                            {latestPayDate && latestPayDate.getTime() !== 0
                                ? dayjs(latestPayDate).format("DD-MM-YYYY")
                                : '-'}
                        </td>
                    </div>
                );
            },
        },

    ];


    return (
        <Card style={{ height: '100vh', }}>
            <Col span={24} style={{ textAlign: 'center' }}>
                <div className="text-center">
                    <h2>รับเข้าค่างวด</h2>
                </div>
            </Col>
            <Spin spinning={loading} size="large" tip="Loading..." >
                <div className="responsive-table">
                    <Row justify={'center'} style={{ marginBottom: 10 }}>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <span>เลือกบัญชี : </span>
                            <Select
                                style={{
                                    width: 280,
                                    height: '40px'
                                }}
                                value={selectedValueType}
                                onChange={onSelectType}
                            >
                                {countoptionsType()}
                            </Select>
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <Button
                                style={{ marginRight: "10px", color: 'white', height: '40px', background: '#302e94f7' }}
                                onClick={onQueryContno}
                            >
                                <FaDatabase />
                                <b>ดึงข้อมูล</b>
                            </Button>
                        </div>
                    </Row>
                    <Row style={{ textAlign: 'center', width: '100%' }} justify={'center'} className='mains' align="middle">
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <span>เลือกประเภท : </span>
                            {checkLoadData === 0 ?
                                <>
                                    <Select
                                        disabled
                                        defaultValue={0}
                                        style={{
                                            width: 280,
                                            height: '40px'
                                        }}
                                        value={selectedValue}
                                    // onChange={onSelect}
                                    >
                                        {/* {countoptions()} */}
                                    </Select>
                                </>
                                :
                                <>
                                    <Select
                                        defaultValue={0}
                                        style={{
                                            width: 280,
                                            height: '40px'
                                        }}
                                        value={selectedValue}
                                        onChange={onSelect}
                                    >
                                        {countoptions()}
                                    </Select>
                                </>
                            }
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            {checkLoadData === 0 ?
                                <>
                                    <Input
                                        disabled
                                        // value={queryContno}
                                        style={{ width: "280px", height: '40px' }}
                                        placeholder="ค้นหาเลขที่สัญญา"
                                        // onChange={handleInputChange}
                                        // onKeyDown={handleKeyDown}
                                        name="search"
                                        id="search"
                                    />
                                </>
                                :
                                <>
                                    <Input
                                        value={queryContno}
                                        style={{ width: "280px", height: '40px' }}
                                        placeholder="ค้นหาเลขที่สัญญา"
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        name="search"
                                        id="search"
                                    />
                                </>
                            }
                        </div>
                        <div style={{ margin: 2, textAlign: 'center' }}>
                            <Button
                                style={{ marginRight: "5px", height: '40px' }}
                                onClick={onQuery}
                            >
                                <SearchOutlined />
                            </Button>
                        </div>
                        <div>
                            <Col span={24}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                    >
                                        <div style={{ gap: '8px', }}>
                                            <Checkbox
                                                onChange={(e) => handleChange("takebrotheraun", e.target.checked)}
                                                checked={dataforsearch.takebrotheraun} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาเร่งรัดนอก ({countTellAun || 0})
                                            </Checkbox>

                                            <Checkbox
                                                onChange={(e) => handleChange("takethecasetoclose", e.target.checked)}
                                                checked={dataforsearch.takethecasetoclose} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาเคสปิด ({countclose || 0})
                                            </Checkbox>

                                            <Checkbox
                                                onChange={(e) => handleChange("excludingseizedvehicles", e.target.checked)}
                                                checked={dataforsearch.excludingseizedvehicles} // แสดงสถานะปัจจุบัน
                                            >
                                                ไม่เอาสัญญาที่ส่งการตลาด ({countMk || 0})
                                            </Checkbox>

                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Table
                            key={dataArray?.length}
                            style={{ marginTop: "5px" }}
                            scroll={{ y: 550, }}
                            columns={columns}
                            dataSource={dataArray}
                        />
                    </Row>
                </div>
            </Spin>
        </Card>
    )
}

export default MainNew