import React from 'react'

import { other_debtor_columns } from '../Data_Tables'
import { other_debtor_data } from '../Data_Tables'
import { Row, Col, Table } from 'antd';


function Other_Debtor_Details() {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: 10 }}>
                <Table columns={other_debtor_columns} dataSource={other_debtor_data} onChange={onChange} />
            </Col>
        </Row>
    )
}

export default Other_Debtor_Details