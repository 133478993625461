import React, { useState, useEffect, useRef, useCallback } from "react";
import { DatabaseOutlined, HomeFilled, PhoneOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Form, Select, Spin, Flex, Tag, message, Space, Radio, Card, notification, Drawer, Divider, DatePicker, Statistic, Modal, FloatButton } from 'antd';
import axios from "axios";
import dayjs from "dayjs";
import "../../css/tooltip.css"
import {
    DownloadOutlined,
    CloseOutlined,
    ProfileOutlined,
    RetweetOutlined,
    ExclamationCircleFilled,
    CreditCardOutlined,
} from "@ant-design/icons";
import { columns1 } from "./Data_Tables";
import { useNavigate } from "react-router-dom";
import { FaClone, FaPhoneAlt, FaSearch } from "react-icons/fa";
import { AiTwotonePhone } from "react-icons/ai";


const op =
    [
        {
            label: 'รหัสลูกค้า', value: 'รหัสลูกค้า',
        },
        {
            label: 'ชื่อ', value: 'ชื่อ',
        },
        {
            label: 'นามสกุล', value: 'นามสกุล',
        },
        {
            label: 'ชื่อเล่น', value: 'ชื่อเล่น',
        },
        {
            label: 'เลขที่บัตร', value: 'เลขที่บัตร',
        },
        {
            label: 'เลขทะเบียนบ้าน', value: 'เลขทะเบียนบ้าน',
        },
        {
            label: 'อื่นๆ', value: '-',
        },
    ]

export default function MainOperators() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [arrayTable, setArrayTable] = useState([]);

    const [dataPost, setDataPost] = useState({ day1: "2023-01-01", day2: "2024-01-01" })
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [dataType, setDataType] = useState()
    const [modalCont, setModalCont] = useState(false)
    const [text, setText] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [open, setOpen] = React.useState(false);
    const [radioselect, setRadioSelect] = useState('1')


    console.log("open", open)
    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "12%",
            render: (text, object, index) => index + 1
        },
        {
            title: "เบอร์โทร",
            dataIndex: "phone",
            key: 'phone',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                // console.log("record",record)
                <>{record.phone}</>
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                // console.log("record",record)
                <>{record.CONTNO}</>
            ),
        },
        {
            title: "วันที่",
            dataIndex: "SDATE",
            width: "30%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.SDATE !== "" ?
                            <Space>
                                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: "30%",
            render: (text, record) => {

                //* รี ต้อง 6 งวด / ปรับโครงสร้าง เกรด A-B ไม่ได้ *//
                const newOfferDate = dayjs(record.newOfferDate).startOf('day'); // วันที่ปลดรี แบบนับแต่วันที่
                const currentDate = dayjs().startOf('day'); // วันที่ปัจจุบัน แบบนับแต่วันที่

                let color
                if (record.approvalStatus === 6) {
                    color = "red"
                }
                if (record.approvalStatus === 5) {
                    color = "gold"
                }
                if (record.approvalStatus === 8) {
                    color = "red"
                }
                if (record.approvalStatus === 7) {
                    color = "geekblue"
                }
                if (record.approvalStatus === 2) {
                    color = "lime"
                }
                if (record.approvalStatus === 22) {
                    color = "red"
                }
                if (record.approvalStatus === 3) {
                    color = "green"
                }
                if (record.approvalStatus === 21) {
                    color = "green"
                }
                if (record.approvalStatus === 11) {
                    color = "gold"
                }

                return (
                    <>
                        {/* <Countdown title="สามารถ รี/ปรับ หลังจาก" value={dayjs(newOfferDate).toDate()} format="D วัน H ชม. m นาที s วินาที" /> */}
                        {/* <Space>
                            <Button
                                type="button" // ตั้งค่า type เป็น "button"
                                style={{ backgroundColor: "blue", width: '50px',color:'white' }}
                                icon={<FaClone size={20}/>}
                                onClick={handleButtonClick}
                            >
                            </Button>
                            <Button
                                type="button" // ตั้งค่า type เป็น "button"
                                style={{ backgroundColor: "green",width:'50px',color:'white'}}
                                icon={<FaPhoneAlt size={20} />}
                                onClick={() => {
                                    setModalCont(true)
                                    setDataCont(record)
                                    setDataType("โทร")
                                }}
                            >
                                
                            </Button>
                        </Space> */}
                        <Space>
                            <div className="button-with-tooltip">
                                <span className="tooltip-text">เปิดแท็บใหม่</span>
                                <Button
                                    type="button"
                                    style={{ backgroundColor: "blue", width: '50px', color: 'white' }}
                                    icon={<FaClone size={20} />}
                                    onClick={handleButtonClick}
                                />
                            </div>
                            <div className="button-with-tooltip">
                                <span className="tooltip-text">โทรออก</span>
                                <Button
                                    type="button"
                                    style={{ backgroundColor: "green", width: '50px', color: 'white' }}
                                    icon={<FaPhoneAlt size={20} />}
                                    onClick={() => {
                                        setModalCont(true);
                                        setDataCont(record);
                                        setDataType("โทร");
                                    }}
                                />
                            </div>
                        </Space>
                    </>
                )
            }
        },
    ]
    const datas = [
        {
            key: 1,
            phone: '0987776564',
            statuscall: false,
            CONTNO: '2-00012212',
            grad: 'D',
            customer_id: '0697868687',
            name: 'ทดสอบระบบ',
            surname: 'นะครับ',
            nickname: 'เทส',
            card_number: '134234333334455',
            registration_number: '26345264523',
            description: (
                <Row justify={'center'}>

                    <Col span={24}>
                        <Form.Item label='หมายเหตุ : '>
                            <Input
                                style={{ display: "inline-block" }}
                                placeholder="Enter additional information"
                            />
                        </Form.Item>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        <Button
                            type="primary"

                        >
                            บันทึก
                        </Button>
                    </Col>
                </Row>
            ),
        },
        {
            key: 2,
            phone: '0987776234',
            statuscall: true,
            CONTNO: '',
            grad: 'A',
            customer_id: '0697123133',
            name: 'กาแฟ',
            surname: 'นะครับนะครับ',
            nickname: 'แฟ',
            card_number: '237424362456345',
            registration_number: '34553453454',
            description: (
                <>
                    <Row justify={'center'}>
                        <Col span={24}>
                            <Form.Item label='หมายเหตุ : '>
                                <Input
                                    style={{ display: "inline-block" }}
                                    placeholder="Enter additional information"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 3,
            phone: '0987723423',
            statuscall: true,
            CONTNO: '2-0001222',
            grad: 'A',
            customer_id: '0456435634345',
            name: 'แซค',
            surname: 'แซคคุง',
            nickname: 'แซค',
            card_number: '3475634653455',
            registration_number: '3453780988767',
            description: 'This not expandable',
        },
        {
            key: 4,
            phone: '0987443322',
            statuscall: false,
            CONTNO: '2-0001226',
            grad: 'X',
            customer_id: '0456435634345',
            name: 'แซค',
            surname: 'แซคคุง',
            nickname: 'แซค',
            card_number: '3475634653455',
            registration_number: '3453780988767',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 5,
            phone: '0767845674',
            statuscall: true,
            CONTNO: '2-00012912',
            grad: 'F',
            customer_id: '99999999999',
            name: 'ไมเคิล',
            surname: 'จอแดน',
            nickname: 'สมยศ',
            card_number: '77767867867667',
            registration_number: '33333333333',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 6,
            phone: '09856545645',
            statuscall: true,
            CONTNO: '',
            grad: 'A',
            customer_id: '8888888888888',
            name: 'มิเกวซัน',
            surname: 'เดเมน',
            nickname: 'มิเกล',
            card_number: '888888866666',
            registration_number: '875667575675',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 7,
            phone: '0434567656',
            statuscall: false,
            CONTNO: '2-0001662',
            grad: 'B',
            customer_id: '64563464564545',
            name: 'เดวิค',
            surname: 'คิมจอง',
            nickname: 'วิค',
            card_number: '323243233333',
            registration_number: '44444444443',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 8,
            phone: '0746567645',
            statuscall: true,
            CONTNO: '',
            grad: 'A',
            customer_id: '3234232333322',
            name: 'เดียร์',
            surname: 'วิเศษ',
            nickname: 'หญิง',
            card_number: '64656454453333',
            registration_number: '4344444443243',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
        {
            key: 9,
            phone: '',
            statuscall: true,
            CONTNO: '8-00012534',
            grad: 'C',
            customer_id: '3234232333322',
            name: 'เดียร์',
            surname: 'วิเศษ',
            nickname: 'หญิง',
            card_number: '64656454453333',
            registration_number: '4344444443243',
            description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        },
    ];


    useEffect(() => {
        if (searchValue === '') {
            setArrayTable([]);
        }
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);

    };


    const dataCall = () => {
        // กรองข้อมูลตามค่า radioselect และนับข้อมูลแยกตามเงื่อนไข
        const total = datas.length;
        const trueCount = datas.filter(data => data.statuscall === true).length;
        const falseCount = datas.filter(data => data.statuscall === false).length;

        let filteredData = [];

        if (radioselect === '1') {
            filteredData = datas.filter(data => data.phone !== '');  // แสดงข้อมูลทั้งหมด
        } else if (radioselect === '2') {
            filteredData = datas.filter(data => data.statuscall === true && data.phone !== ''); // เฉพาะข้อมูลที่มี statuscall เป็น true
        } else if (radioselect === '3') {
            filteredData = datas.filter(data => data.statuscall === false && data.phone !== ''); // เฉพาะข้อมูลที่มี statuscall เป็น false
        } else {
            filteredData = datas;
        }

        return {
            data: filteredData,      // ข้อมูลที่กรองได้ตาม radioselect
            totalCount: total,       // จำนวนข้อมูลทั้งหมด
            trueCount: trueCount,    // จำนวนที่ statuscall เป็น true
            falseCount: falseCount   // จำนวนที่ statuscall เป็น false
        };
    };

    // เรียกใช้ dataCall และแสดงผลในตารางพร้อมตัวนับข้อมูล
    const { data, totalCount, trueCount, falseCount } = dataCall();

    const onChange = (e) => {
        console.log(`radio checked:${e.target.value}`);
        const value = e.target.value
        setRadioSelect(value)
    };

    // const handleButtonClick = (event) => {
    //     console.log('Button clicked!');
    //     navigate('/new_page_collector'); // เปลี่ยน path ตามต้องการ
    // };
    const handleButtonClick = (event) => {
        // event.preventDefault(); 
        console.log('Button clicked!');
        window.open('/new_page_operator', '_blank'); // เปิด path ในแท็บใหม่
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
        if (searchValue === '') {
            setArrayTable([]);
        }
    };

    const handleKeyPress = (e) => {
        console.log("wewe", e.target.value)
        if (e.key === 'Enter') {
            if (dataPost?.day2 === '') {
                const des = "ยังไม่ได้เลือกวันที่"
                // AlertModal({ des })
            } else if (dataPost?.day2) {
                handleSearch(e);
                setText(e.target.value)
            }
        }
    };

    const handleSelectChange = (value) => {
        console.log("value", value)
        setSearchValue('')
        setSelectValue(value)

    }

    const handleSearch = () => {
        let filteredData = [];

        if (selectValue === 'รหัสลูกค้า') {
            filteredData = datas?.filter(item => {
                console.log("itemcustomer_id", item);
                return item.customer_id.includes(searchValue);
            });
        } else if (selectValue === 'ชื่อ') {
            filteredData = datas?.filter(item => {
                // console.log("itemname", item);
                return item.name.toLowerCase().includes(searchValue.toLowerCase());
            });
        } else if (selectValue === 'นามสกุล') {
            filteredData = datas?.filter(item => {
                // console.log("itemsurname", item);
                return item.surname.toLowerCase().includes(searchValue.toLowerCase());
            });
        } else if (selectValue === 'ชื่อเล่น') {
            filteredData = datas?.filter(item => {
                // console.log("itemnickname", item);
                return item.nickname.toLowerCase().includes(searchValue.toLowerCase());
            });
        } else if (selectValue === 'เลขที่บัตร') {
            filteredData = datas?.filter(item => {
                // console.log("itemcard_number", item);
                return item.card_number.includes(searchValue);
            });
        } else if (selectValue === 'เลขทะเบียนบ้าน') {
            filteredData = datas?.filter(item => {
                // console.log("itemregistration_number", item);
                return item.registration_number.includes(searchValue);
            });
        } else {
            filteredData = datas?.filter(item => {
                // console.log("itemCONTNO phone", item);
                return item.CONTNO.includes(searchValue) || item.phone.includes(searchValue);
            });
        }

        console.log("filteredData", filteredData);
        if (!filteredData || filteredData.length === 0) {
            // กรณี filteredData เป็นอาร์เรย์ว่างหรือไม่มีค่า
            console.log("เช็คการเลือกเงื่อนไข"); // ข้อความแจ้งเตือนใน console
            // alert("เช็คการเลือกเงื่อนไข"); // แสดงข้อความแจ้งเตือนในหน้าเว็บ
            message.warning("เช็คการเลือกเงื่อนไข");
        }
        setArrayTable(filteredData);  // อัพเดทตารางด้วยข้อมูลที่กรองแล้ว
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <b><u>ข้อมูลประวัติการโทร</u></b>
                        <Button
                            onClick={onClose}
                            style={{ backgroundColor: 'red', color: 'white' }} // ตั้งค่าสีพื้นหลังและข้อความ
                            icon={<CloseOutlined />} // ใส่ไอคอน "x"
                        >
                        </Button>
                    </div>
                }
                placement="right"
                open={open}
                width={450}
                onClose={onClose}
                mask={false} // ปิดการแสดง overlay
                closable={false} // ปิดปุ่มกากบาท
                style={{
                    background: 'white', // ตั้งค่าสีพื้นหลังที่ต้องการ
                }}
            >
                <Col span={24}
                    style={{
                        padding: 5,
                        backgroundColor: '#f0f0f0',
                        borderRadius: 8,
                        margin: 2,
                    }} >

                    <Col span={24}>
                        <Row justify={'center'} style={{ marginBottom: 5 }}>
                            <Flex vertical gap="middle">
                                <Radio.Group onChange={onChange} defaultValue="1">
                                    <Radio.Button value="1"><b>ทั้งหมด ({totalCount})</b></Radio.Button>
                                    <Radio.Button value="2" style={{ backgroundColor: 'green', color: 'white' }}><b>สายที่ได้รับ ({trueCount})</b></Radio.Button>
                                    <Radio.Button value="3" style={{ backgroundColor: 'red', color: 'white' }}><b>สายที่่ไม่ได้รับ ({falseCount})</b></Radio.Button>
                                </Radio.Group>
                            </Flex>

                        </Row>
                        <Row justify={'center'}>
                            <Col span={24}>
                                <Table
                                    rowKey={(record) => record.key || record.uid}
                                    columns={columns1}
                                    expandable={{

                                        rowExpandable: (record) => record?.name !== 'Not Expandable',
                                        expandedRowRender: (record) => (
                                            <>
                                                <Card>
                                                    <Divider orientation="center" style={{ margin: 2 }}>
                                                        <b><u>รายละเอียดสัญญา</u></b>
                                                    </Divider>
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ margin: 0 }}> {/* Change <p> to <div> */}
                                                                <span>เกรด : </span>
                                                                <b style={{ color: 'blue', fontSize: '20px' }}>
                                                                    {record?.grad}
                                                                </b>
                                                            </div>
                                                            <div style={{ margin: 0 }}> {/* Change <p> to <div> */}
                                                                <span>เลขสัญญา : </span>
                                                                <b>
                                                                    {record?.CONTNO}
                                                                </b>
                                                            </div>
                                                            <div style={{ margin: 0 }}> {/* Change <p> to <div> */}
                                                                <span>ชื่อ : </span>
                                                                <b>
                                                                    {record?.name + ' ' + record?.surname}
                                                                </b>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </Card>

                                            </>
                                        ),
                                    }}
                                    dataSource={data}
                                />
                            </Col>
                        </Row>
                    </Col>

                </Col>
            </Drawer>
            <Card
                style={{
                    height: '100vh',
                    // backgroundColor: '#f0f0f0'
                }}
            >
                <Form
                    form={form}
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={handleSubmit}
                    autoComplete="off"
                >

                    <FloatButton.Group
                        // open={open} 
                        style={{ right: 14 }}>
                        {/* <FloatButton
                            icon={<ProfileOutlined />}
                            title="ตารางการผ่อน"
                            style={{ boxShadow: "0 4px 8px" }}
                        // onClick={showDrawer}
                        ></FloatButton> */}
                        <FloatButton
                            icon={<PhoneOutlined />}
                            title="ประวัติการโทร"
                            style={{ boxShadow: "0 4px 8px" }}
                            onClick={showDrawer}
                        />
                        {/* <FloatButton
                            icon={<CloseOutlined />}
                            style={{ boxShadow: "0 4px 8px" }}
                            title="ปิด"
                        // onClick={() => {
                        //     handleClose();
                        //     console.log("ปิด modal");
                        // }}
                        ></FloatButton> */}
                    </FloatButton.Group>
                    <Row style={{ textAlign: 'center' }}>

                        <Col>
                            <Spin spinning={loading} size='large' tip=" Loading... ">
                                <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>Operators</u></b></Divider>
                                <Row className="main" justify={'center'}>
                                    <div>
                                        <Col span={24} style={{ margin: '10px' }}>
                                            <b>{"เงื่อนไข : "}</b>
                                            <Select
                                                placeholder="เลือก...."
                                                style={{ width: '200px', height: '40px', textAlign: 'left' }}
                                                onChange={(value) => handleSelectChange(value)}
                                                options={op}
                                            />
                                        </Col>
                                    </div>
                                    <div>
                                        <Col span={24} style={{ margin: '10px' }}>
                                            <b>{"ข้อความที่จะค้น : "}</b>
                                            {selectValue === '' ?
                                                <>
                                                    <Input
                                                        disabled
                                                        style={{ width: '200px', height: '40px' }}
                                                        placeholder="ค้นหา...."
                                                        value={searchValue}
                                                        onChange={handleInputChange}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <Input
                                                        style={{ width: '200px', height: '40px' }}
                                                        placeholder="ค้นหา...."
                                                        value={searchValue}
                                                        onChange={handleInputChange}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </>
                                            }

                                        </Col>
                                    </div>
                                    <div>
                                        <Col span={24} style={{ margin: '10px' }}>
                                            <Button style={{ marginLeft: '15px', height: '40px' }}
                                                type="primary"
                                                onClick={(e) => handleSearch(e)}
                                            >
                                                {/* ค้นหา */}
                                                <FaSearch size={20} />
                                            </Button>
                                        </Col>
                                    </div>
                                </Row>
                                <Row justify={'left'}>
                                    <Col span={open ? 17 : 24}
                                        style={{
                                            transition: 'all 0.5s ease', // กำหนด transition ให้เลื่อนอย่างนุ่มนวลภายใน 0.5 วินาที
                                            padding: 5,
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: 8,
                                            margin: 2,
                                        }}
                                    >
                                        <Table
                                            rowKey={(record) => record.key || record.uid} // ใช้ `key` หรือ `uid` ที่กำหนดไว้
                                            dataSource={arrayTable}
                                            columns={columns}
                                            scroll={{
                                                y: 600,
                                                x: 600,
                                            }}
                                        >
                                        </Table>
                                    </Col>

                                </Row>
                                <Divider />
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Space>
                                        <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                                    </Space>
                                </Col>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Card >
            {/* {
                modalCont ?
                    <Contno open={modalCont} close={setModalCont} text={text} data={dataCont} dataType={dataType} />
                    : null
            } */}
        </>
    )
};





