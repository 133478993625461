import React from 'react'
import { Card, Col } from 'antd';

export default function MainFollow() {
  return (
    <>
      <Card style={{ height: '100vh', }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="text-center">
            <h2><b>ติดตามเคส</b></h2>
          </div>
        </Col>


      </Card>

    </>
  )
}
