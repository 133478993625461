import { Col, Row, Table } from 'antd';
import React from 'react'
import { Debt_Obligation_Schedule_columns, Debt_Obligation_Schedule_data } from '../../Data_Tables';

const Debt_Obligation_Schedule = () => {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: 10 }}>
                <Table columns={Debt_Obligation_Schedule_columns} dataSource={Debt_Obligation_Schedule_data} onChange={onChange} />
            </Col>
        </Row>
    )
}

export default Debt_Obligation_Schedule