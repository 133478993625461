import React, { useState } from "react";
import { Button, Form, Input, Layout, Row, Spin, Typography, Modal } from "antd";
import axios from "axios";
import { Content, Footer } from "antd/es/layout/layout";
import logo from "../assets/Images/logo.png";
import "./css/Media.css"
import { login } from "./mid/all-api";
const { Title } = Typography;

export default function SignIn({ onLogin }) {
    const [loading, setLoading] = useState(false);
    const error = () => {
        Modal.error({
            title: 'Error',
            content: 'ล็อคอินไม่สำเร็จ กรุณาตรวจสอบ username และ password',
        })
    }
    const error2 = () => {
        Modal.error({
            title: 'Error',
            content: 'user นี้ไม่สามารถเข้าสู่ระบบได้ เนื่องจาก user ถูกปิดไปแล้ว',
        })
    }

    const onFinish = async (value) => {
        const user = value
        setLoading(true)
        try {
            const res = await axios.post(login, user);
            if (res.data.status === "success") {
                localStorage.setItem('token', JSON.stringify(res.data.token));
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('branch', res.data.branch);
                localStorage.setItem('menu', JSON.stringify(res.data.permission));
                localStorage.setItem('nickname', res.data?.nickname);
                localStorage.setItem('nicknameSalcod', res.data?.nicknameSalcod);
                localStorage.setItem('firstname', res.data?.firstname);
                localStorage.setItem('lastname', res.data?.lastname);
                localStorage.setItem('idSep', res.data?.idSep);
                localStorage.setItem('idDepartment', res.data?.idDepartment);
                localStorage.setItem('roleId', res.data?.roleId);
                setLoading(false); // ปิด Loading
                // window.location.reload(false)
                // window.location.href = '/';
                const token = res.data.token;
                onLogin(token);
            } else {
                error2();
                setLoading(false);
            }
        } catch (err) {
            if (err.response?.status === 404) {
                error();
            } else {
                error();
            }
            console.log("err", err);
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    return (
        <Layout
            style={{ height: "100vh" }}
        >
            <Content >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >
                        <Row span={24} style={{ textAlign: 'center' }} className="main6">
                            <aside style={{ textAlign: 'center' }}>
                                <img src={logo} width={'80%'} alt="logo" />
                                <div style={{ width: '100%', textAlign: 'center', alignItems: 'center', padding: '20px' }}>
                                    <Title style={{ textAlign: 'center', color: '#002766' }} ><b>เข้าสู่ระบบ</b></Title>
                                    <Form.Item
                                        style={{ marginBottom: '8px' }}
                                        className="username"
                                        label={<span style={{ color: '#002766', fontFamily: 'sans - serif', fontSize: '20px' }}><b>ชื่อผู้ใช้</b></span>}
                                        name="username"

                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "กรุณากรอกรหัสผู้ใช้งาน",
                                                },
                                            ]}
                                    >
                                        <Input placeholder="Username" style={{ height: '40px' }} />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label={<span style={{ color: '#002766', fontFamily: 'sans - serif', fontSize: '20px' }}><b>รหัสผ่าน</b></span>}
                                        name="password"
                                        style={{ marginBottom: '8px' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "กรุณากรอกพาสเวิร์ด",
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder="Password" style={{ height: '40px' }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: "100%", height: '40px' }}
                                        >
                                            <span style={{ color: '#ffffff', fontFamily: 'sans - serif', fontSize: '20px' }}><b>เข้าสู่ระบบ</b></span>
                                        </Button>
                                    </Form.Item>
                                </div>
                            </aside>
                        </Row>
                    </Form>
                </Spin>
            </Content>
            <Footer justify={'center'}>
                <p className="copyright" style={{ color: '#002766' }}>
                    <b>Copyright ©{" "}</b>
                    <u><a href="https://www.facebook.com/calleasing.kkn" style={{ color: '#002766' }}>
                        <b>One Leasing 2023</b>
                    </a>
                    </u>
                </p>
            </Footer>
        </Layout >

    );
}