import React from 'react'
import { Col, Row, Table } from 'antd';
import { Payment_Receipt_Schedule_columns, Payment_Receipt_Schedule_data } from '../../Data_Tables';

const Payment_Receipt_Schedule = () => {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <Row style={{ width: '100%' }}>
            <Col span={24} style={{ padding: 10 }}>
                <Table
                    scroll={{
                        x: 2000,
                        y: 500,
                    }}
                    columns={Payment_Receipt_Schedule_columns}
                    dataSource={Payment_Receipt_Schedule_data}
                    onChange={onChange}
                />
            </Col>
        </Row>
    )
}

export default Payment_Receipt_Schedule