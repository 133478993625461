import { Col, Divider, Flex, Radio, Row } from 'antd'
import React, { useState } from "react";
import Debt_Obligation_Schedule from './Debt_Obligation_Schedule';
import Installment_Payment_Schedule from './Installment_Payment_Schedule';
import Payment_Receipt_Schedule from './Payment_Receipt_Schedule';
import Tracking_Record_Schedule from './Tracking_Record_Schedule';
import Installment_Deposit_Schedule from './Installment_Deposit_Schedule';

function Payment_Schedule() {

    const [radioselect_table, setRadioSelectTable] = useState('1')

    const onChange = (e) => {
        console.log(`radio checked:${e.target.value}`);
        const value = e.target.value
        setRadioSelectTable(value)
    };

    // Debt Obligation Schedule
    // Installment Payment Schedule
    // Payment Receipt Schedule
    // Tracking Record Schedule
    // Installment Deposit Schedule

    // Debt_Obligation_Schedule    ตารางภาระหนี้
    // Installment_Payment_Schedule    ตารางดิวชำระงวด
    // Payment_Receipt_Schedule      ตารางรับชำระ
    // Tracking_Record_Schedule      ตารางบันทึกติดตาม
    // Installment_Deposit_Schedule    ตารางรับฝากค่างวด

    return (
        <Row style={{ width: '100%' }}>
            <Divider style={{ margin: 2 }} />
            <Col span={24}>
                <Row style={{ marginBottom: 5 }}>
                    <Flex vertical gap="middle">
                        <Radio.Group onChange={onChange} defaultValue="1">
                            <Radio.Button value="1">ตารางภาระหนี้</Radio.Button>
                            <Radio.Button value="2">ตารางดิวชำระงวด</Radio.Button>
                            <Radio.Button value="3">ตารางรับชำระ</Radio.Button>
                            <Radio.Button value="4">ตารางบันทึกติดตาม</Radio.Button>
                            <Radio.Button value="5">ตารางรับฝากค่างวด</Radio.Button>
                        </Radio.Group>
                    </Flex>

                </Row>
                <Row>
                    {radioselect_table === '1' && (<Debt_Obligation_Schedule />)}
                    {radioselect_table === '2' && (<Installment_Payment_Schedule />)}
                    {radioselect_table === '3' && (<Payment_Receipt_Schedule />)}
                    {radioselect_table === '4' && (<Tracking_Record_Schedule />)}
                    {radioselect_table === '5' && (<Installment_Deposit_Schedule />)}
                </Row>
            </Col>
        </Row>
    )
}

export default Payment_Schedule